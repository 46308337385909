import React, { useEffect, useRef, useState } from 'react';

import Header from '../../../shared/components/header/Header';
import Footer from '../../../shared/components/footer/Footer';
import AboutUs from '../../aboutUs/AboutUs';
import Slider from '../../carousel/Slider';

import { companyData } from '../../../shared/constants/constants';

import { Container, VideoContainer } from './styles';
import Products from '../../products/Products';
import Solutions from '../../solutions/Solutions';
import WhyHireUs from '../../whyHireUs/WhyHireUs';
import Testimonials from '../../testimonials/Testimonials';
import FollowUs from '../../followUs/FollowUs';
import HouzzAwards from '../../houzzAwards/HouzzAwards';
import ContactForm from '../../contactForm/ContactForm';

const Home = () => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const [initialPositionWhenScrollTop, setInitialPositionWhenScrollTop] = useState(0);
	const [categoriesToRender, setCategoriesToRender] = useState('');
	const [hide, setHide] = useState(false);

	const ourServicesRef = useRef();
	const followUsRef = useRef();
	const aboutUsRef = useRef();
	const whyHireUsRef = useRef();

	const refs = {
		aboutUs: aboutUsRef,
		ourServices: ourServicesRef,
		socialNetworks: followUsRef,
		whyHireUs: whyHireUsRef,
	};

	const handleScroll = () => {
		const position = window.scrollY;
		if (scrollPosition >= 200) {
			if (scrollPosition < position) {
				setHide(true);
				setInitialPositionWhenScrollTop(0);
			} else if (initialPositionWhenScrollTop === 0) {
				setInitialPositionWhenScrollTop(scrollPosition);
			} else if (initialPositionWhenScrollTop - scrollPosition > 100) {
				setHide(false);
			}
		} else {
			setHide(false);
		}
		setScrollPosition(position);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [scrollPosition]);

	return (
		<Container>
			<Header data={companyData} hide={hide} refs={refs} />
			<VideoContainer>
				<video
					autoPlay
					loop
					muted
					playsInline
					src="https://res.cloudinary.com/dwpo1pgnh/video/upload/v1649183327/HomeVideo_qbmihe.mp4"
					style={{
						tabIndex: '-1',
						objectFit: 'cover',
						objectPosition: 'center center',
						opacity: 1,
						fittingType: 'cover',
						width: `100%`,
						height: `100%`,
						top: 0,
						left: 0,
					}}
				/>
				{/* <GridBackgroundImage /> */}
			</VideoContainer>
			<AboutUs aboutUsRef={aboutUsRef} />
			<Products ourServicesRef={ourServicesRef} setCategoriesToRender={setCategoriesToRender} />
			<Slider
				categoriesToRender={categoriesToRender}
				setCategoriesToRender={setCategoriesToRender}
				showSlider={categoriesToRender}
			/>
			<Solutions />
			<WhyHireUs whyHireUsRef={whyHireUsRef} />
			<FollowUs followUsRef={followUsRef} />
			<ContactForm />
			<VideoContainer secondaryVideo>
				<video
					autoPlay
					playsInline
					loop
					muted
					src="https://res.cloudinary.com/dwpo1pgnh/video/upload/v1649183325/BottomVideo_vxoamn.mp4"
					style={{
						tabIndex: '-1',
						objectFit: 'cover',
						objectPosition: 'center center',
						position: 'relative',
						opacity: 1,
						fittingType: 'fill',
						width: `100%`,
						height: `100%`,
					}}
				/>
			</VideoContainer>
			<HouzzAwards />
			<Testimonials />
			<Footer data={companyData} refs={refs} />
		</Container>
	);
};

export default Home;
