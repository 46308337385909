import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Text from '../../shared/components/text/Text';
import {
	ButtonContainer,
	Container,
	IconContainer,
	ItemsContainer,
	ProductContainer,
	ProductImage,
	TextProduct,
	TitleCategoryContainer,
	TitleContainer,
	ViewMore,
} from './styles';

import Kitchens from '../../assets/img/Kitchens.jpg';
import Bathroom from '../../assets/img/Bathroom.jpg';
import WallUnit from '../../assets/img/WallUnit.jpg';
import Office from '../../assets/img/Office.jpg';
import colors from '../../styles/colors';

const Products = ({ setCategoriesToRender, ourServicesRef }) => {
	const [hover, setHover] = useState(false);

	const items = [
		{
			image: Kitchens,
			title: 'KITCHEN REMODELING',
			category: 'Kitchens',
			categoryTitle: ['KIT', 'CHE', '*NS'],
			text: (
				<>
					&bull; Cabinets <br />
					&bull; Countertops <br />
					&bull; BackSplash <br />
					&bull; Sink and Faucets
				</>
			),
		},
		{
			image: Bathroom,
			title: 'BATHROOM REMODELING',
			category: 'Bathrooms',
			categoryTitle: ['BATH', 'ROO', '*MS'],
			text: (
				<>
					&bull; Vanity <br />
					&bull; Shower Head and Faucet <br />
					&bull; Porcelain Sink <br />
					&bull; ShowerGlass <br />
					&bull; Toilets <br />
					&bull; Mirror an Lighting <br />
					&bull; Porcelain Tile
				</>
			),
		},
		{
			image: WallUnit,
			title: 'FULL HOUSE REMODELING',
			category: 'WallUnits',
			categoryTitle: ['REM', 'ODE', '*LING'],
			text: (
				<>
					&bull; Porcelain Tile <br />
					&bull; Stones <br />
					&bull; Plumbing <br />
					&bull; Eletrical <br />
					&bull; Tile Installation <br />
					&bull; Carpinter <br />
					&bull; Countertop <br />
					&bull; Painting <br />
					&bull; Glass Shower door <br />
					&bull; Windows an Doors <br />
				</>
			),
		},
		{
			image: Office,
			title: 'CUSTOM CABINETRY',
			category: 'Offices',
			categoryTitle: ['CAB', 'INE', '*TRY'],
			text: (
				<>
					&bull; Kitchen <br />
					&bull; Vanity <br />
					&bull; WallUnit <br />
					&bull; Closet <br />
					&bull; Laundry
				</>
			),
		},
	];

	const renderItems = () =>
		items.map((item) => (
			<ProductContainer
				onMouseOver={() => {
					setTimeout(() => {
						setHover(true);
					}, 100);
				}}
				onMouseLeave={() => {
					setHover(false);
				}}
			>
				<ViewMore>
					<Text
						marginTop="0"
						type="h1"
						style={{
							marginBottom: '48px',
							fontSize: '38px',
							letterSpacing: '3px',
							textShadow: '#333 0.2em 0.2em 0.3em',
							fontFamily: 'Roboto',
						}}
					>
						{item.title}
					</Text>
					<IconContainer>
						<Text marginTop="0px" type="h3" style={{ fontFamily: 'Roboto', textShadow: '#333 0.2em 0.2em 0.3em' }}>
							See More
						</Text>
					</IconContainer>
				</ViewMore>
				<TextProduct>
					<TitleCategoryContainer>
						{item.categoryTitle.map((text) => (
							<Text
								marginTop="0"
								type="h6"
								style={{
									letterSpacing: '3px',
									textShadow: '#333 0.2em 0.2em 0.3em',
									webkitTextFillColor: `${text.includes('*') ? 'transparent' : ''}`,
									webkitTextStrokeColor: '#FFF',
									webkitTextStrokeWidth: `${text.includes('*') ? '2px' : ''}`,
									fontFamily: 'Roboto',
								}}
							>
								{text.replace('*', '')}
							</Text>
						))}
					</TitleCategoryContainer>
					<Text
						marginTop="0px"
						type="h2"
						style={{
							fontSize: '16px',
							lineHeight: '24px',
							textShadow: '#333 0.2em 0.2em 0.3em',
							marginBottom: '24px',
							textAlign: 'left',
							fontFamily: 'Roboto',
						}}
					>
						{item.text}
					</Text>
					<ButtonContainer
						onClick={() => {
							if (hover) {
								setCategoriesToRender(item.category);
							}
						}}
					>
						<Text marginTop="0px" type="h3" style={{ fontFamily: 'Roboto', textShadow: '#333 0.2em 0.2em 0.3em' }}>
							View Pictures
						</Text>
					</ButtonContainer>
				</TextProduct>
				<ProductImage src={item.image} />
			</ProductContainer>
		));

	return (
		<Container ref={ourServicesRef}>
			<TitleContainer>
				<Text
					type="h2"
					marginTop="0"
					color={colors.grey900}
					style={{
						padding: 32,
						lineHeight: 2,
						userSelect: 'none',
					}}
				>
					CREATE A SPACE THAT DEFINES YOU
				</Text>
			</TitleContainer>
			<ItemsContainer>{renderItems()}</ItemsContainer>
		</Container>
	);
};

Products.propTypes = {
	setCategoriesToRender: PropTypes.func.isRequired,
	ourServicesRef: PropTypes.instanceOf(Object).isRequired,
};

export default Products;
