import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';
import { StyledCheckbox } from './styles';

const Checkbox = ({ label, setValue }) => {
	const [checked, setChecked] = useState(false);

	const handleChange = () => {
		setChecked((oldState) => !oldState);
		setValue();
	};

	return (
		<div>
			<FormControlLabel
				control={<StyledCheckbox color="default" checked={checked} onChange={handleChange} size="small" />}
				label={label}
			/>
		</div>
	);
};

Checkbox.defaultProps = {
	label: '',
};

Checkbox.propTypes = {
	label: PropTypes.string,
	setValue: PropTypes.func.isRequired,
};

export default Checkbox;
