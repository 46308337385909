export const companyData = {
	address: '6401 E. Roger Circle Suite7 | Boca Raton – FL 33487',
	phone: '(561) 989 0307',
	email: 'lunardi@lunardidecor.com',
	mapLocation: 'https://goo.gl/maps/nhn1Zi7RmyDta2Td7',
};

export const socialNetworks = {
	facebook: 'https://www.facebook.com/lunardi.decor/',
	instagram: 'https://www.instagram.com/lunardidecor/',
	pinterest: 'https://br.pinterest.com/lunardidecor/pins/',
	houzz: 'https://www.houzz.com/professionals/kitchen-and-bath-remodelers/lunardi-decor-pfvwus-pf~95007517?',
	whatsapp: 'https://api.whatsapp.com/send?phone=15617270302',
};

export const breakpoints = {
	xs: '0px',
	sm: '600px',
	md: '900px',
	lg: '1200px',
	xl: '1400px',
};
