import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	padding: 32px;
`;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
`;

export const FormContainer = styled.div`
	max-width: 1024px;
`;
