import { TextField } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../styles/colors';

export const StyledTextField = styled(TextField)({
	'& .MuiFormLabel-root': {
		fontSize: '16px',
		fontWeight: 500,
		color: colors.grey600,
	},
	'& .MuiFormLabel-root.Mui-focused': {
		fontWeight: 500,
		color: colors.grey900,
	},
	'& .MuiOutlinedInput-root': {
		'&:hover fieldset': {
			borderColor: colors.grey400,
		},
		'&.Mui-focused fieldset': {
			borderColor: colors.grey400,
		},
	},
	'&& .MuiInput-root:hover::before': {
		borderColor: colors.grey400,
	},
	'& .MuiInputBase-input': {
		fontSize: '0.875rem',
	},
	'& .MuiInputBase-root': {
		color: colors.grey900,
		fontWeight: 500,
	},
	'& label + .MuiInput-formControl': {
		marginBottom: '0.5rem',
	},
	'& .MuiFormLabel-filled': {
		color: colors.grey900,
		fontWeight: 500,
	},
	'& .MuiFormLabel-filled + .MuiInput-underline:after': {
		transform: 'none',
	},
});

export default StyledTextField;
