import { Drawer, List, ListItem } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon } from '@mui/material';
import { GoTo, GoToContainer } from './styles';
import { scrollToRef } from '../../functions/utils';
import Text from '../text/Text';
import colors from '../../../styles/colors';
import Service from '../../../assets/svg/Service';
import People from '../../../assets/svg/People';
import Contact from '../../../assets/svg/Contact';
import HandShake from '../../../assets/svg/HandShake';

const NavBar = ({ open, setOpen, refs }) => {
	const toggleDrawer = (state) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setOpen(state);
	};
	return (
		<Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
			<List>
				<GoToContainer>
					<ListItem
						button
						divider
						onClick={() => {
							scrollToRef(refs.aboutUs);
							setOpen(false);
						}}
					>
						<GoTo>
							<ListItemIcon>
								<People width={28} height={28} />
							</ListItemIcon>
							<Text color={colors.black} type="h3" marginTop="0px" style={{ fontWeight: 500, userSelect: 'none' }}>
								About Us
							</Text>
						</GoTo>
					</ListItem>
					<ListItem
						button
						divider
						onClick={() => {
							scrollToRef(refs.ourServices);
							setOpen(false);
						}}
					>
						<GoTo>
							<ListItemIcon>
								<Service width={28} height={28} />
							</ListItemIcon>
							<Text color={colors.black} type="h3" marginTop="0px" style={{ fontWeight: 500, userSelect: 'none' }}>
								Services
							</Text>
						</GoTo>
					</ListItem>
					<ListItem
						button
						divider
						onClick={() => {
							scrollToRef(refs.whyHireUs);
							setOpen(false);
						}}
					>
						<GoTo>
							<ListItemIcon>
								<HandShake />
							</ListItemIcon>
							<Text color={colors.black} type="h3" marginTop="0px" style={{ fontWeight: 500, userSelect: 'none' }}>
								Why Hire Us
							</Text>
						</GoTo>
					</ListItem>
					<ListItem
						button
						divider
						onClick={() => {
							scrollToRef(refs.socialNetworks);
							setOpen(false);
						}}
					>
						<GoTo>
							<ListItemIcon>
								<Contact fill="#555" style={{ marginLeft: 4 }} width={24} height={24} />
							</ListItemIcon>
							<Text color={colors.black} type="h3" marginTop="0px" style={{ fontWeight: 500, userSelect: 'none' }}>
								Contacts
							</Text>
						</GoTo>
					</ListItem>
				</GoToContainer>
			</List>
		</Drawer>
	);
};

NavBar.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	refs: PropTypes.instanceOf(Object).isRequired,
};

export default NavBar;
