import styled from 'styled-components';

export const TitleContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	text-align: center;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	flex-wrap: wrap;
`;

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
`;

export const NumberContainer = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	padding-left: 4px;
	padding-bottom: 4px;
`;

export const FlipContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	height: 300px;
	:after {
		content: '';
		background: rgba(0, 0, 0, 0.3);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}
	${(props) => `
    background-image: url(${props.imageBackground});   
    `}
	${(props) =>
		!props.front &&
		`
        filter: grayscale(100%);
    `}
	background-size: cover;
	background-position: bottom left;
`;
