import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin: 48px;
`;

export const TitleContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const ReviewContainer = styled.div``;

export const SlideReview = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

export const AvatarContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
`;

export const MessageContainer = styled.div``;

export const NameContainer = styled.div``;
