import { configureStore } from '@reduxjs/toolkit';

const reducer = {
};

const store = configureStore({
  reducer,
});

export default store;
