const colors = {
	black: '#000',
	white: '#FFF',
	blue050: '#EAF3FF',
	blue100: '#B3D3FF',
	blue200: '#80B6FF',
	blue300: '#4D98FF',
	blue400: '#006CFF',
	blue500: '#1D40EE',
	primary: '#072AD8',
	blue700: '#041AC2',
	blue900: '#020BA3',
	blue800: '#00305E',
	orange050: '#FFF4EE',
	orange060: '#FACA66',
	orange100: '#FFCEB6',
	orange200: '#FFAE85',
	orange300: '#FF8E54',
	orange400: '#FF7530',
	secondary: '#FF5D0B',
	orange600: '#E75308',
	orange700: '#C93D02',
	orange800: '#B63700',
	orange900: '#AA2D01',
	grey010: '#6D6E71',
	grey020: '#333333',
	grey033: '#545454',
	grey050: '#F9F9F9',
	grey100: '#EDEEF0',
	grey200: '#D3D5DA',
	grey250: '#DDDDDD',
	grey300: '#999DA9',
	grey400: '#838896',
	grey500: '#6D7384',
	grey600: '#595F70',
	grey700: '#3A4050',
	grey900: '#231F20',
	yellow050: '#FFF7DE',
	yellow100: '#FFEAAD',
	yellow200: '#FFDD77',
	yellow300: '#FFD03A',
	yellow400: '#FFC500',
	yellow500: '#FFBB00',
	yellow600: '#F9A900',
	red050: '#FFEBEE',
	red100: '#FFCDD2',
	red200: '#F09A99',
	red600: '#E73933',
	red800: '#C82826',
	red900: '#B91B1A',
	green050: '#E5F8EC',
	green100: '#C1EDCF',
	green300: '#68D790',
	green400: '#3ACD77',
	green600: '#00B354',
	green800: '#008F3B',
	green900: '#006F27',
};

export default colors;
