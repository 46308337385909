import Kitchen from '../../assets/img/Kitchens/Kitchen.jpg';
import Kitchen2 from '../../assets/img/Kitchens/Kitchen2.jpg';
import Kitchen3 from '../../assets/img/Kitchens/Kitchen3.jpg';
import Kitchen4 from '../../assets/img/Kitchens/Kitchen4.jpg';
import Kitchen5 from '../../assets/img/Kitchens/Kitchen5.jpg';
import Kitchen6 from '../../assets/img/Kitchens/Kitchen6.jpg';
import Kitchen7 from '../../assets/img/Kitchens/Kitchen7.jpg';
import Kitchen8 from '../../assets/img/Kitchens/Kitchen8.jpg';
import Kitchen9 from '../../assets/img/Kitchens/Kitchen9.jpg';
import Kitchen10 from '../../assets/img/Kitchens/Kitchen10.jpg';
import Kitchen11 from '../../assets/img/Kitchens/Kitchen11.jpg';
import Kitchen12 from '../../assets/img/Kitchens/Kitchen12.jpg';
import Kitchen13 from '../../assets/img/Kitchens/Kitchen13.jpg';
import Kitchen14 from '../../assets/img/Kitchens/Kitchen14.jpg';
import Kitchen15 from '../../assets/img/Kitchens/Kitchen15.jpg';
import Kitchen16 from '../../assets/img/Kitchens/Kitchen16.jpg';
import Kitchen17 from '../../assets/img/Kitchens/Kitchen17.jpg';
import Kitchen18 from '../../assets/img/Kitchens/Kitchen18.jpg';
import Kitchen19 from '../../assets/img/Kitchens/Kitchen19.jpg';
import Kitchen20 from '../../assets/img/Kitchens/Kitchen20.jpg';
import Kitchen21 from '../../assets/img/Kitchens/Kitchen21.jpg';
import Kitchen22 from '../../assets/img/Kitchens/Kitchen22.jpg';
import Kitchen23 from '../../assets/img/Kitchens/Kitchen23.jpg';
import Kitchen24 from '../../assets/img/Kitchens/Kitchen24.jpg';
import Kitchen25 from '../../assets/img/Kitchens/Kitchen25.jpg';

import Office from '../../assets/img/Offices/Office.jpg';
import Office2 from '../../assets/img/Offices/Office2.jpg';
import Office3 from '../../assets/img/Offices/Office3.jpg';
import Office4 from '../../assets/img/Offices/Office4.jpg';
import Office5 from '../../assets/img/Offices/Office5.jpg';
import Office6 from '../../assets/img/Offices/Office6.jpg';
import Office7 from '../../assets/img/Offices/Office7.jpg';
import Office8 from '../../assets/img/Offices/Office8.jpg';
import Office9 from '../../assets/img/Offices/Office9.jpg';
import Office10 from '../../assets/img/Offices/Office10.jpg';
import Office11 from '../../assets/img/Offices/Office11.jpg';
import Office12 from '../../assets/img/Offices/Office12.jpg';
import Office13 from '../../assets/img/Offices/Office13.jpg';
import Office14 from '../../assets/img/Offices/Office14.jpg';
import Office15 from '../../assets/img/Offices/Office15.jpg';
import Office16 from '../../assets/img/Offices/Office16.jpg';
import Office17 from '../../assets/img/Offices/Office17.jpg';
import Office18 from '../../assets/img/Offices/Office18.jpg';
import Office19 from '../../assets/img/Offices/Office19.jpg';
import Office20 from '../../assets/img/Offices/Office20.jpg';
import Office21 from '../../assets/img/Offices/Office21.jpg';
import Office22 from '../../assets/img/Offices/Office22.jpg';
import Office23 from '../../assets/img/Offices/Office23.jpg';
import Office24 from '../../assets/img/Offices/Office24.jpg';
import Office25 from '../../assets/img/Offices/Office25.jpg';
import Office26 from '../../assets/img/Offices/Office26.jpg';
import Office27 from '../../assets/img/Offices/Office27.jpg';
import Office28 from '../../assets/img/Offices/Office28.jpg';

import Bathroom from '../../assets/img/Bathrooms/Bathroom.jpg';
import Bathroom2 from '../../assets/img/Bathrooms/Bathroom2.jpg';
import Bathroom3 from '../../assets/img/Bathrooms/Bathroom3.jpg';
import Bathroom4 from '../../assets/img/Bathrooms/Bathroom4.jpg';
import Bathroom5 from '../../assets/img/Bathrooms/Bathroom5.jpg';
import Bathroom6 from '../../assets/img/Bathrooms/Bathroom6.jpg';
import Bathroom7 from '../../assets/img/Bathrooms/Bathroom7.jpg';
import Bathroom8 from '../../assets/img/Bathrooms/Bathroom8.jpg';
import Bathroom9 from '../../assets/img/Bathrooms/Bathroom9.jpg';
import Bathroom10 from '../../assets/img/Bathrooms/Bathroom10.jpg';
import Bathroom11 from '../../assets/img/Bathrooms/Bathroom11.jpg';
import Bathroom12 from '../../assets/img/Bathrooms/Bathroom12.jpg';
import Bathroom13 from '../../assets/img/Bathrooms/Bathroom13.jpg';
import Bathroom14 from '../../assets/img/Bathrooms/Bathroom14.jpg';
import Bathroom15 from '../../assets/img/Bathrooms/Bathroom15.jpg';
import Bathroom16 from '../../assets/img/Bathrooms/Bathroom16.jpg';
import Bathroom17 from '../../assets/img/Bathrooms/Bathroom17.jpg';
import Bathroom18 from '../../assets/img/Bathrooms/Bathroom18.jpg';
import Bathroom19 from '../../assets/img/Bathrooms/Bathroom19.jpg';
import Bathroom20 from '../../assets/img/Bathrooms/Bathroom20.jpg';
import Bathroom21 from '../../assets/img/Bathrooms/Bathroom21.jpg';
import Bathroom22 from '../../assets/img/Bathrooms/Bathroom22.jpg';
import Bathroom23 from '../../assets/img/Bathrooms/Bathroom23.jpg';
import Bathroom24 from '../../assets/img/Bathrooms/Bathroom24.jpg';
import Bathroom25 from '../../assets/img/Bathrooms/Bathroom25.jpg';

import WallUnit from '../../assets/img/WallUnits/WallUnit.jpg';
import WallUnit2 from '../../assets/img/WallUnits/WallUnit2.jpg';
import WallUnit3 from '../../assets/img/WallUnits/WallUnit3.jpg';
import WallUnit4 from '../../assets/img/WallUnits/WallUnit4.jpg';
import WallUnit5 from '../../assets/img/WallUnits/WallUnit5.jpg';
import WallUnit6 from '../../assets/img/WallUnits/WallUnit6.jpg';
import WallUnit7 from '../../assets/img/WallUnits/WallUnit7.jpg';
import WallUnit8 from '../../assets/img/WallUnits/WallUnit8.jpg';
import WallUnit9 from '../../assets/img/WallUnits/WallUnit9.jpg';
import WallUnit10 from '../../assets/img/WallUnits/WallUnit10.jpg';
import WallUnit11 from '../../assets/img/WallUnits/WallUnit11.jpg';
import WallUnit12 from '../../assets/img/WallUnits/WallUnit12.jpg';
import WallUnit13 from '../../assets/img/WallUnits/WallUnit13.jpg';
import WallUnit14 from '../../assets/img/WallUnits/WallUnit14.jpg';
import WallUnit15 from '../../assets/img/WallUnits/WallUnit15.jpg';
import WallUnit16 from '../../assets/img/WallUnits/WallUnit16.jpg';
import WallUnit17 from '../../assets/img/WallUnits/WallUnit17.jpg';
import WallUnit18 from '../../assets/img/WallUnits/WallUnit18.jpg';
import WallUnit19 from '../../assets/img/WallUnits/WallUnit19.jpg';
import WallUnit20 from '../../assets/img/WallUnits/WallUnit20.jpg';
import WallUnit21 from '../../assets/img/WallUnits/WallUnit21.jpg';
import WallUnit22 from '../../assets/img/WallUnits/WallUnit22.jpg';
import WallUnit23 from '../../assets/img/WallUnits/WallUnit23.jpg';
import WallUnit24 from '../../assets/img/WallUnits/WallUnit24.jpg';
import WallUnit25 from '../../assets/img/WallUnits/WallUnit25.jpg';
import WallUnit26 from '../../assets/img/WallUnits/WallUnit26.jpg';
import WallUnit27 from '../../assets/img/WallUnits/WallUnit27.jpg';
import WallUnit28 from '../../assets/img/WallUnits/WallUnit28.jpg';
import WallUnit29 from '../../assets/img/WallUnits/WallUnit29.jpg';
import WallUnit30 from '../../assets/img/WallUnits/WallUnit30.jpg';
import WallUnit31 from '../../assets/img/WallUnits/WallUnit31.jpg';
import WallUnit32 from '../../assets/img/WallUnits/WallUnit32.jpg';
import WallUnit33 from '../../assets/img/WallUnits/WallUnit33.jpg';
import WallUnit34 from '../../assets/img/WallUnits/WallUnit34.jpg';
import WallUnit35 from '../../assets/img/WallUnits/WallUnit35.jpg';
import WallUnit36 from '../../assets/img/WallUnits/WallUnit36.jpg';
import WallUnit37 from '../../assets/img/WallUnits/WallUnit37.jpg';
import WallUnit38 from '../../assets/img/WallUnits/WallUnit38.jpg';
import WallUnit39 from '../../assets/img/WallUnits/WallUnit39.jpg';
import WallUnit40 from '../../assets/img/WallUnits/WallUnit40.jpg';
import WallUnit41 from '../../assets/img/WallUnits/WallUnit41.jpg';
import WallUnit42 from '../../assets/img/WallUnits/WallUnit42.jpg';
import WallUnit43 from '../../assets/img/WallUnits/WallUnit43.jpg';
import WallUnit44 from '../../assets/img/WallUnits/WallUnit44.jpg';
import WallUnit45 from '../../assets/img/WallUnits/WallUnit45.jpg';
import WallUnit46 from '../../assets/img/WallUnits/WallUnit46.jpg';
import WallUnit47 from '../../assets/img/WallUnits/WallUnit47.jpg';
import WallUnit48 from '../../assets/img/WallUnits/WallUnit48.jpg';
import WallUnit49 from '../../assets/img/WallUnits/WallUnit49.jpg';
import WallUnit50 from '../../assets/img/WallUnits/WallUnit50.jpg';
import WallUnit51 from '../../assets/img/WallUnits/WallUnit51.jpg';
import WallUnit52 from '../../assets/img/WallUnits/WallUnit52.jpg';
import WallUnit53 from '../../assets/img/WallUnits/WallUnit53.jpg';
import WallUnit54 from '../../assets/img/WallUnits/WallUnit54.jpg';
import WallUnit55 from '../../assets/img/WallUnits/WallUnit55.jpg';
import WallUnit56 from '../../assets/img/WallUnits/WallUnit56.jpg';
import WallUnit57 from '../../assets/img/WallUnits/WallUnit57.jpg';
import WallUnit58 from '../../assets/img/WallUnits/WallUnit58.jpg';

export const kitchensPictures = [
	Kitchen,
	Kitchen2,
	Kitchen3,
	Kitchen4,
	Kitchen5,
	Kitchen6,
	Kitchen7,
	Kitchen8,
	Kitchen9,
	Kitchen10,
	Kitchen11,
	Kitchen12,
	Kitchen13,
	Kitchen14,
	Kitchen15,
	Kitchen16,
	Kitchen17,
	Kitchen18,
	Kitchen19,
	Kitchen20,
	Kitchen21,
	Kitchen22,
	Kitchen23,
	Kitchen24,
	Kitchen25,
];

export const officesPictures = [
	Office,
	Office2,
	Office3,
	Office4,
	Office5,
	Office6,
	Office7,
	Office8,
	Office9,
	Office10,
	Office11,
	Office12,
	Office13,
	Office14,
	Office15,
	Office16,
	Office17,
	Office18,
	Office19,
	Office20,
	Office21,
	Office22,
	Office23,
	Office24,
	Office25,
	Office26,
	Office27,
	Office28,
];

export const wallUnitsPictures = [
	WallUnit,
	WallUnit2,
	WallUnit3,
	WallUnit4,
	WallUnit5,
	WallUnit6,
	WallUnit7,
	WallUnit8,
	WallUnit9,
	WallUnit10,
	WallUnit11,
	WallUnit12,
	WallUnit13,
	WallUnit14,
	WallUnit15,
	WallUnit16,
	WallUnit17,
	WallUnit18,
	WallUnit19,
	WallUnit20,
	WallUnit21,
	WallUnit22,
	WallUnit23,
	WallUnit24,
	WallUnit25,
	WallUnit26,
	WallUnit27,
	WallUnit28,
	WallUnit29,
	WallUnit30,
	WallUnit31,
	WallUnit32,
	WallUnit33,
	WallUnit34,
	WallUnit35,
	WallUnit36,
	WallUnit37,
	WallUnit38,
	WallUnit39,
	WallUnit40,
	WallUnit41,
	WallUnit42,
	WallUnit43,
	WallUnit44,
	WallUnit45,
	WallUnit46,
	WallUnit47,
	WallUnit48,
	WallUnit49,
	WallUnit50,
	WallUnit51,
	WallUnit52,
	WallUnit53,
	WallUnit54,
	WallUnit55,
	WallUnit56,
	WallUnit57,
	WallUnit58,
];

export const bathroomsPictures = [
	Bathroom,
	Bathroom2,
	Bathroom3,
	Bathroom4,
	Bathroom5,
	Bathroom6,
	Bathroom7,
	Bathroom8,
	Bathroom9,
	Bathroom10,
	Bathroom11,
	Bathroom12,
	Bathroom13,
	Bathroom14,
	Bathroom15,
	Bathroom16,
	Bathroom17,
	Bathroom18,
	Bathroom19,
	Bathroom20,
	Bathroom21,
	Bathroom22,
	Bathroom23,
	Bathroom24,
	Bathroom25,
];
