/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';

const Houzz = ({ ...props }) => (
	<svg width={100} height={100} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" {...props}>
		<path
			fill={props.fill ? props.fill : '#9AC641'}
			d="m150.035 246.907 55.445-32.829v-63.839l-55.445 32.828zM150.035 118.85l55.445 31.389V86.022z"
		/>
		<path fill={props.fill ? 'transparent' : '#010101'} d="M150.035 118.85v64.217l55.445-32.828z" />
		<path
			fill={props.fill ? props.fill : '#9AC641'}
			d="M94.52 151.787v64.217l55.446-32.829zM149.966 53.093 94.52 85.922v65.865l55.446-32.828z"
		/>
		<path fill={props.fill ? 'transparent' : '#fff'} d="M149.966 118.959 94.52 151.787l55.446 31.388z" />
	</svg>
);

Houzz.propTypes = {
	fill: PropTypes.string.isRequired,
};

export default Houzz;
