import { MenuItem, Select } from '@mui/material';
import styled from 'styled-components';
import colors from '../../../styles/colors';

export const StyledSelect = styled(Select)({
	'&.MuiOutlinedInput-root.Mui-focused fieldset': {
		borderColor: colors.grey400,
	},
});

export const StyledMenuItem = styled(MenuItem)``;
