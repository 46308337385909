import styled from 'styled-components';
import Facebook from '../../../assets/svg/Facebook';
import Houzz from '../../../assets/svg/Houzz';
import Instagram from '../../../assets/svg/Instagram';
import Location from '../../../assets/svg/Location';
import Mail from '../../../assets/svg/Mail';
import Phone from '../../../assets/svg/Phone';
import Pinterest from '../../../assets/svg/Pinterest';
import Whatsapp from '../../../assets/svg/Whatsapp';
import Youtube from '../../../assets/svg/Youtube';
import colors from '../../../styles/colors';
import { breakpoints } from '../../constants/constants';

export const Container = styled.div`
	display: flex;
	justify-content: space-around;
	background-color: ${colors.grey010};
	width: 100%;
	padding-bottom: 32px;
	@media (max-width: ${breakpoints.lg}) {
		flex-direction: column;
		text-align: center;
	}
`;

export const LogoContainer = styled.div`
	display: flex;
	padding: 32px 0px;
	@media (max-width: ${breakpoints.lg}) {
		justify-content: center;
	}
`;

export const GoToContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 64px;
	@media (max-width: ${breakpoints.lg}) {
		padding: 0;
	}
`;

export const CallNowContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 64px;
`;

export const ContactContainer = styled.a`
	display: flex;
	align-items: center;
	text-decoration: none;
	margin-top: 12px;
	&:not(:hover) {
		h4 {
			color: ${colors.grey050} !important;
		}
		svg {
			fill: ${colors.grey050} !important;
		}
		path {
			fill: ${colors.grey050} !important;
		}
	}
	@media (max-width: ${breakpoints.lg}) {
		justify-content: center;
	}
`;

export const MailIcon = styled(Mail)`
	height: 18px;
	width: 18px;
`;

export const PhoneIcon = styled(Phone)`
	height: 18px;
	width: 18px;
`;

export const LocationIcon = styled(Location)`
	height: 18px;
	width: 18px;
`;

export const GoTo = styled.div`
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	:active {
		opacity: 0.6;
	}
	&:not(:hover) {
		h4 {
			color: ${colors.grey050} !important;
		}
	}
`;

export const FollowUsContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 64px;
`;

export const SocialNetworksContainer = styled.div`
	display: flex;
	margin-top: 16px;
	margin-left: -8px;
	@media (max-width: ${breakpoints.lg}) {
		justify-content: center;
		margin-left: 0;
	}
`;

export const InstagramIcon = styled(Instagram)`
	height: 36px;
	width: 36px;
	path {
		fill: white;
	}
`;

export const FacebookIcon = styled(Facebook)`
	height: 24px;
	width: 36px;
	margin-left: -4px;
	path {
		fill: white;
	}
`;

export const HouzzIcon = styled(Houzz)`
	height: 36px;
	width: 36px;
	margin-left: -16px;
	padding: 0;
	path {
		fill: white;
		:nth-child(even) {
			fill: ${colors.grey010};
		}
	}
`;

export const PinterestIcon = styled(Pinterest)`
	height: 24px;
	width: 36px;
	margin-left: -16px;
	path {
		fill: white;
	}
`;

export const YoutubeIcon = styled(Youtube)`
	height: 36px;
	width: 36px;
	margin-left: -8px;
	path:last-of-type {
		fill: white;
	}
`;

export const WhatsappIcon = styled(Whatsapp)`
	height: 24px;
	width: 36px;
	path {
		fill: white;
	}
`;

export const IconContainer = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	height: 36px;
	width: 36px;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	&:hover ${InstagramIcon} {
		path {
			fill: ${colors.black};
		}
	}
	&:hover ${FacebookIcon} {
		path {
			fill: ${colors.black};
		}
	}
	&:hover ${HouzzIcon} {
		path {
			fill: ${colors.black};
			:nth-child(even) {
				fill: ${colors.grey010};
			}
		}
	}
	&:hover ${PinterestIcon} {
		path {
			fill: ${colors.black};
		}
	}
	&:hover ${YoutubeIcon} {
		path:last-of-type {
			fill: ${colors.black};
		}
	}
	&:hover ${WhatsappIcon} {
		path {
			fill: ${colors.black};
		}
	}
`;

export const LicensedContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
