import styled from 'styled-components';
import Instagram from '../../../assets/svg/Instagram';
import Mail from '../../../assets/svg/Mail';
import Phone from '../../../assets/svg/Phone';
import Location from '../../../assets/svg/Location';
import colors from '../../../styles/colors';
import { breakpoints } from '../../constants/constants';
import Houzz from '../../../assets/svg/Houzz';
import Whatsapp from '../../../assets/svg/Whatsapp';

export const Container = styled.div`
	${(props) =>
		props.hide
			? `
		visibility: hidden;
  		opacity: 0;
  		`
			: ''};
	display: flex;
	flex-direction: column;
	flex: 1;
	z-index: 10;
	background-color: ${colors.grey050};
	width: 100%;
	position: fixed;
	transition: visibility 0.2s, opacity 0.2s linear;
`;

export const ContactContainer = styled.div`
	display: flex;
	width: 100%;
	background-color: ${colors.grey010};
	height: 50px;
`;

export const Contacts = styled.div`
	display: flex;
	padding-left: 5%;
`;

export const TextContacts = styled.div`
	@media (max-width: ${breakpoints.lg}) {
		display: flex;
	}
	display: none;
	align-items: center;
`;

export const DescriptionContacts = styled.div`
	display: none;
	align-items: center;
	@media (min-width: ${breakpoints.lg}) {
		display: flex;
	}
`;

export const MailIcon = styled(Mail)`
	height: 18px;
	width: 18px;
`;

export const PhoneIcon = styled(Phone)`
	height: 18px;
	width: 18px;
`;

export const LocationIcon = styled(Location)`
	height: 18px;
	width: 18px;
`;

export const MailContainer = styled.a`
	display: flex;
	align-items: center;
	text-decoration: none;
	margin-left: 4px;
	&:not(:hover) ${MailIcon} {
		path {
			fill: ${colors.grey050};
		}
	}
	&:not(:hover) {
		h5 {
			color: ${colors.grey050} !important;
		}
	}
`;

export const PhoneContainer = styled.a`
	display: flex;
	align-items: center;
	text-decoration: none;
	margin-left: 6px;
	&:not(:hover) ${PhoneIcon} {
		path {
			fill: ${colors.grey050};
		}
	}
	&:not(:hover) {
		h5 {
			color: ${colors.grey050} !important;
		}
	}
`;
export const LocationContainer = styled.a`
	display: flex;
	align-items: center;
	text-decoration: none;
	margin-left: 4px;
	&:not(:hover) ${LocationIcon} {
		path {
			fill: ${colors.grey050};
		}
	}
	&:not(:hover) {
		h5 {
			color: ${colors.grey050} !important;
		}
	}
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 26px;
	width: 26px;
	box-sizing: border-box;
	cursor: pointer;
`;

export const SocialNetworksContainer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: flex-end;
	padding-right: 5%;
`;

export const InstagramIcon = styled(Instagram)`
	height: 26px;
	width: 26px;
`;

export const InstagramContainer = styled.a`
	display: flex;
	height: 26px;
	width: 26px;
	margin-left: 6px;
	align-items: center;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	&:not(:hover) ${InstagramIcon} {
		path {
			fill: white;
		}
	}
	&:hover {
		background-color: ${colors.grey100};
		border-radius: 16px;
	}
`;

export const WhatsappIcon = styled(Whatsapp)`
	height: 18px;
	width: 18px;
`;

export const WhatsappContainer = styled.a`
	display: flex;
	height: 26px;
	padding: 1px;
	width: 26px;
	margin-left: 4px;
	align-items: center;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	&:not(:hover) ${WhatsappIcon} {
		path {
			fill: white;
		}
	}
	&:hover ${WhatsappIcon} {
		path {
			fill: #25d366;
		}
	}
	&:hover {
		background-color: ${colors.grey100};
		border-radius: 16px;
	}
`;

export const HouzzIcon = styled(Houzz)`
	height: 26px;
	width: 26px;
`;

export const HouzzContainer = styled.a`
	display: flex;
	height: 26px;
	width: 26px;
	margin-left: 4px;
	align-items: center;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	&:not(:hover) ${HouzzIcon} {
		path {
			fill: white;
			:nth-child(even) {
				fill: ${colors.grey010};
			}
		}
	}
	&:hover {
		background-color: ${colors.grey100};
		border-radius: 16px;
	}
`;

export const NavigationContainer = styled.div`
	display: flex;
	width: 100%;
`;

export const GoToContainer = styled.div`
	display: none;
	flex: 1;
	flex-direction: row;
	justify-content: ${(props) => (props.left ? 'flex-end' : 'flex-start')};
	align-items: center;
	text-align: center;
	@media (min-width: ${breakpoints.lg}) {
		display: flex;
	}
`;

export const GoTo = styled.div`
	margin: 0px 90px;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	:active {
		opacity: 0.6;
	}
`;

export const MenuIconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 16px;
	margin-right: -62px;
	z-index: 1;
	@media (min-width: ${breakpoints.lg}) {
		display: none;
	}
`;

export const LogoContainer = styled.div`
	padding-left: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100px;
	@media (max-width: ${breakpoints.lg}) {
		flex: 1;
	}
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	flex-grow: 2;
	@media (min-width: ${breakpoints.sm}) {
		flex-direction: row;
	}
`;

export const AddressContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
	@media (min-width: ${breakpoints.sm}) {
		margin-right: 32px;
	}
`;

export const Address = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media (max-width: ${breakpoints.sm}) {
		justify-content: flex-start;
	}
`;

export const PhoneContainer2 = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: flex-start;
`;
