/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grow } from '@material-ui/core';

import Text from '../../shared/components/text/Text';
import colors from '../../styles/colors';

import AboutUsImg from '../../assets/img/AboutUs.jpg';

import { Container, Image, ImageContainer, InfoContainer, TextContainer, TitleContainer } from './styles';

const AboutUs = ({ aboutUsRef }) => {
	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef();
	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsVisible(true);
				}
			},
			{
				threshold: [0.2],
			}
		);
		if (ref.current) {
			observer.observe(ref.current);
		}
	}, [ref]);
	return (
		<Container ref={aboutUsRef}>
			<TitleContainer>
				<Text
					type="h2"
					marginTop="0"
					color={colors.grey900}
					style={{
						padding: 32,
						lineHeight: 2,
						userSelect: 'none',
					}}
				>
					YOUR INSPIRATION IS EVERYTHING WE DO
				</Text>
			</TitleContainer>
			<InfoContainer>
				<ImageContainer ref={ref}>
					<Grow in={isVisible} style={{ transformOrigin: '0 0 0' }} {...(isVisible ? { timeout: 1000 } : {})}>
						<Image src={AboutUsImg} />
					</Grow>
				</ImageContainer>
				<TextContainer>
					<Text
						type="h2"
						marginTop="0"
						color={colors.grey900}
						style={{ paddingBottom: 16, lineHeight: 2, userSelect: 'none' }}
					>
						ABOUT LUNARDI DECOR
					</Text>
					<Text
						type="h3"
						marginTop="0"
						color={colors.grey900}
						style={{
							fontWeight: 700,
							lineHeight: 2,
							userSelect: 'none',
							textAlign: 'center',
						}}
					>
						OUR MISSION
					</Text>
					<Text
						type="h3"
						marginTop="0"
						color={colors.grey900}
						style={{
							fontWeight: 300,
							lineHeight: 2,
							userSelect: 'none',
						}}
					>
						We are passionate about design. We believe design can transform environments and thrill people. Our mission
						is to working close to our clients to determine and create an environment that is a reflection of their
						needs – whether is a kitchen, a bathroom, a wall unit or an entire home.
					</Text>
					<Text
						type="h3"
						marginTop="8px"
						color={colors.grey900}
						style={{
							fontWeight: 700,
							lineHeight: 2,
							userSelect: 'none',
							textAlign: 'center',
						}}
					>
						OUR HISTORY
					</Text>
					<Text
						type="h3"
						marginTop="0"
						color={colors.grey900}
						style={{
							fontWeight: 300,
							lineHeight: 2,
							userSelect: 'none',
						}}
					>
						We believe everything in life has a purpose. In 2000, when we first met, we realized we shared not only the
						same family values but also the same passion: construction and design. Our marriage materialized our love
						and the opportunity to start a family business, a business that could somehow generate a positive impact to
						other families and their homes. This passion combined with our individual skills and teamwork – a
						constructor engineer descendant of Italians and a professional interior designer – became our recipe for
						excellence to deliver unique customized solutions to our clients in the fascinating world of construction,
						remodeling and interior design.
					</Text>
					<Text
						type="h3"
						marginTop="8px"
						color={colors.grey900}
						style={{
							fontWeight: 700,
							lineHeight: 2,
							userSelect: 'none',
							textAlign: 'center',
						}}
					>
						OUR TEAM
					</Text>
					<Text
						type="h3"
						marginTop="0"
						color={colors.grey900}
						style={{
							fontWeight: 300,
							lineHeight: 2,
							userSelect: 'none',
						}}
					>
						Lunardi Decor believes in creating a company environment that provides comfort and confidence. We believe in
						open communication as a key to achieving your desired results. We pride ourselves on meeting budget and
						scheduling requirements.
					</Text>
				</TextContainer>
			</InfoContainer>
		</Container>
	);
};

AboutUs.propTypes = {
	aboutUsRef: PropTypes.instanceOf(Object).isRequired,
};

export default AboutUs;
