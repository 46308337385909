/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

const Youtube = ({ ...props }) => (
	<svg height={100} viewBox="0 0.03 2498 2502.47" width={100} xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="m1293.24 1938.65-409.54-7.49c-132.6-2.61-265.53 2.6-395.53-24.44-197.76-40.4-211.77-238.49-226.43-404.65-20.2-233.6-12.38-471.44 25.74-703.09C309 669 393.69 591.44 524.66 583c442.12-30.63 887.18-27 1328.32-12.71 46.59 1.31 93.5 8.47 139.44 16.62 226.77 39.75 232.3 264.23 247 453.2 14.66 190.92 8.47 382.82-19.55 572.44-22.48 157-65.49 288.66-247 301.37-227.42 16.62-449.62 30-677.68 25.74.01-1.01-1.3-1.01-1.95-1.01zm-240.77-397.48c171.38-98.4 339.49-195.16 509.89-292.9-171.7-98.4-339.49-195.16-509.89-292.9z"
			fill="#FF0000"
		/>
	</svg>
);

export default Youtube;
