/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import colors from '../../styles/colors';

const Location = ({ ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 297 297"
		style={{
			enableBackground: 'new 0 0 297 297',
		}}
		xmlSpace="preserve"
		{...props}
	>
		<path
			fill={colors.grey900}
			d="M148.5 0C87.43 0 37.747 49.703 37.747 110.797c0 91.026 99.729 179.905 103.976 183.645a10.232 10.232 0 0 0 6.777 2.559c2.421 0 4.841-.853 6.778-2.559 4.245-3.739 103.975-92.618 103.975-183.645C259.253 49.703 209.57 0 148.5 0zm0 272.689c-22.049-21.366-90.243-93.029-90.243-161.892 0-49.784 40.483-90.287 90.243-90.287s90.243 40.503 90.243 90.287c0 68.862-68.194 140.525-90.243 161.892z"
		/>
		<path
			fill={colors.grey900}
			d="M148.5 59.183c-28.273 0-51.274 23.154-51.274 51.614 0 28.461 23.001 51.614 51.274 51.614s51.274-23.153 51.274-51.614c0-28.46-23.001-51.614-51.274-51.614zm0 82.718c-16.964 0-30.765-13.953-30.765-31.104 0-17.15 13.801-31.104 30.765-31.104s30.765 13.953 30.765 31.104-13.801 31.104-30.765 31.104z"
		/>
	</svg>
);

Location.propTypes = {
	fill: PropTypes.string.isRequired,
};

export default Location;
