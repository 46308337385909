import React from 'react';
// import PropTypes from 'prop-types';
import { Container, ImageContainer, TitleContainer, HouzzBadge } from './styles';
import Text from '../../shared/components/text/Text';
import colors from '../../styles/colors';
import Badge1k from '../../assets/img/HouzzBadges/badge_1k_saves.png';
import Badge2018Service from '../../assets/img/HouzzBadges/badge_2018_service.png';
import Badge2019Design from '../../assets/img/HouzzBadges/badge_2019_design.png';
import Badge2019Service from '../../assets/img/HouzzBadges/badge_2019_service.png';
import Badge2020 from '../../assets/img/HouzzBadges/badge_2020.png';
import Badge2021 from '../../assets/img/HouzzBadges/badge_2021.png';

const HouzzAwards = () => (
	<Container>
		<TitleContainer>
			<Text
				type="h2"
				marginTop="0"
				color={colors.grey900}
				style={{
					padding: 32,
					lineHeight: 2,
					userSelect: 'none',
				}}
			>
				BEST OF LUNARDI DECOR
			</Text>
		</TitleContainer>
		<ImageContainer>
			<HouzzBadge className="houzz-thumb-container__badge_48_7" src={Badge1k} alt="Best of Houzz 2020" />
			<HouzzBadge className="houzz-thumb-container__badge_48_7" src={Badge2018Service} alt="Best of Houzz 2020" />
			<HouzzBadge className="houzz-thumb-container__badge_48_7" src={Badge2019Design} alt="Best of Houzz 2020" />
			<HouzzBadge className="houzz-thumb-container__badge_48_7" src={Badge2019Service} alt="Best of Houzz 2020" />
			<HouzzBadge className="houzz-thumb-container__badge_48_7" src={Badge2020} alt="Best of Houzz 2020" />
			<HouzzBadge className="houzz-thumb-container__badge_48_7" src={Badge2021} alt="Best of Houzz 2020" />
		</ImageContainer>
	</Container>
);

HouzzAwards.propTypes = {};

export default HouzzAwards;
