import styled from 'styled-components';
import { breakpoints } from '../../shared/constants/constants';

export const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 16px;
`;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 160px;
`;

export const InfoContainer = styled.div`
	display: flex;
	@media (max-width: ${breakpoints.md}) {
		flex-direction: column;
	}
	flex-direction: row;
	padding: 16px;
`;

export const ImageContainer = styled.div`
	display: flex;
`;

export const Image = styled.img`
	@media (max-width: ${breakpoints.md}) {
		width: 100%;
	}
	width: 50vw;
	height: 70vh;
	object-fit: cover;
`;

export const TextContainer = styled.div`
	display: flex;
	flex-shrink: 1;
	flex-direction: column;
	@media (max-width: ${breakpoints.md}) {
		flex-direction: column;
	}
	text-align: center;
	padding: 0px 32px;
	@media (max-width: ${breakpoints.md}) {
		padding: 32px 32px 0px;
	}
`;
