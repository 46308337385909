/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

const Whatsapp = ({ ...props }) => (
	<svg
		width={100}
		height={100}
		data-name="Layer 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 240 241.19"
		{...props}
	>
		<path
			d="M205 35.05A118.61 118.61 0 0 0 120.46 0C54.6 0 1 53.61 1 119.51a119.5 119.5 0 0 0 16 59.74L0 241.19l63.36-16.63a119.43 119.43 0 0 0 57.08 14.57A119.54 119.54 0 0 0 205 35.07ZM120.5 219a99.18 99.18 0 0 1-50.59-13.9l-3.64-2.17-37.6 9.85 10-36.65-2.35-3.76a99.37 99.37 0 0 1 154.47-123.1A99.43 99.43 0 0 1 120.49 219Zm54.5-74.46c-3-1.51-17.67-8.71-20.39-9.71s-4.72-1.51-6.75 1.51-7.72 9.71-9.46 11.72-3.49 2.27-6.45.76-12.63-4.66-24-14.84a91.1 91.1 0 0 1-16.7-20.68c-1.75-3-.19-4.61 1.33-6.07s3-3.48 4.47-5.23a19.65 19.65 0 0 0 3-5 5.51 5.51 0 0 0-.24-5.23C99 90.27 93 75.57 90.6 69.58s-4.89-5-6.73-5.14-3.73-.09-5.7-.09a11 11 0 0 0-8 3.73C67.48 71.05 59.75 78.3 59.75 93s10.69 28.88 12.19 30.9S93 156.07 123 169c7.12 3.06 12.68 4.9 17 6.32a41.18 41.18 0 0 0 18.8 1.17c5.74-.84 17.66-7.21 20.17-14.18s2.5-13 1.75-14.19-2.69-2.06-5.7-3.59Z"
			fill="#25d366"
			style={{
				fillRule: 'evenodd',
			}}
		/>
	</svg>
);

export default Whatsapp;
