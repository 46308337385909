export const scrollToRef = (ref) => {
	const padding = () => {
		const currentPosition = window.scrollY;
		if (currentPosition > ref.current.offsetTop) {
			return 120;
		}
		return 0;
	};
	window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - padding() });
};

export const verifyAllObrigatoryData = (fieldsArray, valuesForm) => {
	const fieldsWithouValue = [];
	fieldsArray.forEach((field) => {
		if (!valuesForm[field.id]) {
			fieldsWithouValue.push(field.id);
		}
	});
	return fieldsWithouValue;
};

export default scrollToRef;
