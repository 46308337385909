import axios from 'axios';

const CONSTANTS = {
	POST: 'POST',
	GET: 'GET',
	ERROR_CONNECTION: 'Não foi possível conectar ao servidor, verifique sua conexão com a internet.',
	ERROR_ACCESS_DANIED: 'Sem permissão para acesso.',
	ERROR_NOT_FOUND: 'Nenhum retorno encontrado.',
	ERROR_INTERNAL: 'Houve um erro interno no servidor.',
};

export const headerRequests = async () => ({
	'Api-Token': process.env.REACT_APP_API_TOKEN,
	'Access-Control-Allow-Origin': '*',
	'Content-Type': 'application/json',
});

export const call = async (url, method, body, authorization) => {
	let answer;
	let config = {};

	const headers = await headerRequests();

	try {
		config = {
			headers,
		};

		if (authorization !== '') {
			Object.assign(config.headers, { Authorization: authorization });
		}
	} catch (error) {
		config = {};
	}

	switch (method) {
		case CONSTANTS.GET:
			answer = axios.get(url, config);
			break;
		case CONSTANTS.POST:
			answer = axios.post(url, body, config);
			break;
		default:
			answer = 'Método HTTP utilizado para conectar a API é inválido.';
	}

	return answer;
};

export const connect = (url, method, body, authorization = '') =>
	call(url, method, body, authorization).catch((error) => error.response);

export const ConnectApiPost = (url, body, authorization = '') => connect(url, CONSTANTS.POST, body, authorization);

export const ConnectApiGet = (url) => connect(url, CONSTANTS.GET);
