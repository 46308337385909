import styled from 'styled-components';
import { breakpoints } from '../../shared/constants/constants';
import colors from '../../styles/colors';

export const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 160px;
`;

export const ProductImage = styled.img`
	display: flex;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const TextProduct = styled.div`
	display: flex;
	width: calc(100% - 92px);
	padding: 0px 32px;
	flex-direction: column;
	padding-right: 60px;
	align-items: flex-start;
	justify-content: flex-end;
	position: absolute;
	background-color: rgb(0, 0, 0, 0.4);
	height: 100%;
	user-select: none;
	visibility: hidden;
`;

export const TitleCategoryContainer = styled.div`
	transition: margin 1000ms;
	margin-bottom: -150px;
	visibility: hidden;
	z-index: 10;
	text-align: left;
`;

export const ViewMore = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 32px;
	align-items: flex-start;
	justify-content: flex-end;
	position: absolute;
	background-color: rgb(0, 0, 0, 0.2);
	height: 100%;
	user-select: none;
	@media (min-width: ${breakpoints.xs}) {
		width: calc(100% - 32px);
	}
	@media (min-width: ${breakpoints.md}) {
		max-width: 100%;
	}
`;

export const IconContainer = styled.div`
	display: flex;
	justify-content: space-between;
	/* align-self: flex-end; */
	padding: 8px;
	border: 1px solid ${colors.white};
	border-radius: 4px;
	margin-bottom: 48px;
	margin-right: 48px;
	cursor: pointer;
`;

export const ButtonContainer = styled.div`
	padding: 8px;
	border: 1px solid ${colors.white};
	border-radius: 4px;
	margin-bottom: 48px;
	cursor: pointer;
	z-index: 100;
`;

export const ProductContainer = styled.div`
	display: flex;
	margin: 16px;
	height: 90vh;
	transform: translate(0px, 0px);
	&:hover ${TextProduct} {
		visibility: visible;
	}
	&:hover ${ViewMore} {
		visibility: hidden;
	}
	&:hover ${TitleCategoryContainer} {
		visibility: visible;
		margin-bottom: 16px;
	}
	@media (min-width: ${breakpoints.md}) {
		width: calc(50% - 32px);
	}
	@media (min-width: ${breakpoints.xl}) {
		margin: 0px;
	}
`;

export const ItemsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	@media (min-width: ${breakpoints.xs}) {
		flex-direction: column;
	}
	@media (min-width: ${breakpoints.md}) {
		flex-direction: row;
		flex-wrap: wrap;
	}
	@media (min-width: ${breakpoints.xl}) {
		flex-direction: row;
		flex-wrap: nowrap;
		padding-bottom: 32px;
	}
`;
