import _ from 'lodash';
import * as Services from './ContactFormService';

export const sendContactsActiveCampaign = async (formValues, customFields) => {
	const getOptionsString = (options) => {
		let optionsString = '||';
		options.forEach((option) => {
			optionsString += `${option}||`;
		});
		return optionsString;
	};

	const getFieldValues = () =>
		customFields.map((field) => ({
			field: field.id,
			value: _.isArray(formValues[field.id]) ? getOptionsString(formValues[field.id]) : formValues[field.id],
		}));

	const body = {
		contact: {
			email: formValues.email,
			firstName: formValues.firstName,
			lastName: formValues.lastName,
			phone: formValues.phone,
			fieldValues: getFieldValues(),
		},
	};
	const result = Services.sendContactsActiveCampaign(body);
	return result;
};

export default sendContactsActiveCampaign;
