import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import smoothscroll from 'smoothscroll-polyfill';
import Routes from './routes';
import store from './store';
import themes from './styles/themes';

function App() {
	smoothscroll.polyfill();
	return (
		<Provider store={store}>
			<MuiThemeProvider theme={themes.light}>
				<Routes />
			</MuiThemeProvider>
		</Provider>
	);
}

export default App;
