/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';

const Facebook = ({ ...props }) => (
	<svg
		width={props.width}
		height={props.height}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 23.101 23.101"
		style={{
			enableBackground: 'new 0 0 23.101 23.101',
		}}
		xmlSpace="preserve"
		{...props}
	>
		<path
			fill={props.fill}
			d="M8.258 4.458c0-.144.02-.455.06-.931.043-.477.223-.976.546-1.5.32-.522.839-.991 1.561-1.406C11.144.208 12.183 0 13.539 0h3.82v4.163h-2.797c-.277 0-.535.104-.768.309-.231.205-.35.4-.35.581v2.59h3.914c-.041.507-.086 1-.138 1.476l-.155 1.258c-.062.425-.125.819-.187 1.182h-3.462v11.542H8.258V11.558H5.742V7.643h2.516V4.458z"
		/>
	</svg>
);

Facebook.defaultProps = {
	fill: '#4267B2',
	width: 100,
	height: 68,
};

Facebook.propTypes = {
	fill: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
};

export default Facebook;
