/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grow } from '@material-ui/core';

import Text from '../../shared/components/text/Text';

import colors from '../../styles/colors';

import FramelessCabinetsImg from '../../assets/img/FramelessCabinets.jpg';

import { Container, Image, ImageContainer, InfoContainer, TextContainer, TitleContainer } from './styles';

const FramelessCabinets = ({ whyHireUsRef }) => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useRef();

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsVisible(true);
				}
			},
			{
				threshold: [0.2],
			}
		);
		if (ref.current) {
			observer.observe(ref.current);
		}
	}, [ref]);

	return (
		<Container ref={whyHireUsRef}>
			<TitleContainer>
				<Text
					type="h2"
					marginTop="24px"
					color={colors.grey900}
					style={{
						textAlign: 'center',
						padding: 24,
						lineHeight: 2,
						userSelect: 'none',
					}}
				>
					WHY HIRE LUNARDI DECOR?
				</Text>
			</TitleContainer>
			<InfoContainer>
				<ImageContainer ref={ref}>
					<Grow in={isVisible} style={{ transformOrigin: '0 0 0' }} {...(isVisible ? { timeout: 1000 } : {})}>
						<Image src={FramelessCabinetsImg} />
					</Grow>
				</ImageContainer>
				<TextContainer>
					<Text
						type="h3"
						marginTop="8px"
						color={colors.grey900}
						style={{
							fontWeight: 300,
							lineHeight: 2,
							userSelect: 'none',
						}}
					>
						Lunardi Decor offers a wide-ranging skill sets necessary to carry out the whole project and give you a
						quality interior design result. We understand how to minimize the costs of a kitchen or bathroom remodel
						while maximizing the quality; and we have extensive experience in trading and all services associated with
						home remodeling. The Interior Desing help you with planning, and lay-out. We will guide you in choosing from
						the full array of materials, sizes, colors, textures, and arrangements. We can assist you to find high end
						products for surprisingly affordable prices. We Specialize in Custom Design, Complete Remodeling kitchens,
						Complete Bathrooms, Flooring, Porcelain Tile, Woodwork , Closets, Custom Cabinets, Ceiling Lighting, Office,
						Wall Units, Countertops, Doors and Windows. Visit our Showroom em Boca Raton, we have more than 5.000 sqft
						in Samples for you to Choose! We are a dynamic remodeling company that helps Palm Beach area residents
						realize their dream kitchen, bathroom, living room, home office, etc. at an affordable and fair price.
						Lunardi Decor is fully equipped to handle all of your design needs in a timely and customized manner; we
						offer high end products at wholesale prices. We will maintain open communication with you and your design
						team to keep you up to date on the status of your job. We provide customer service as no project are the
						same. We appreciate the opportunity to work with you on your remodeling or renovation project, and we invite
						you to give us a call for a free quote and consultation: +1(561) 989 0307
					</Text>
				</TextContainer>
			</InfoContainer>
		</Container>
	);
};

FramelessCabinets.propTypes = {
	whyHireUsRef: PropTypes.instanceOf(Object).isRequired,
};

export default FramelessCabinets;
