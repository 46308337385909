/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';

import { Dialog, Grow } from '@material-ui/core';

import { bathroomsPictures, kitchensPictures, officesPictures, wallUnitsPictures } from '../../shared/constants/images';

import { ButtonContainer, Image, ImageMask, Container, styles, TitleContainer } from './styles';
import colors from '../../styles/colors';
import Text from '../../shared/components/text/Text';
import Close from '../../assets/svg/Close';

const Slider = ({ categoriesToRender, setCategoriesToRender, showSlider }) => {
	const [swiper, setSwiper] = useState(null);
	const renderSlides = (imagesArray) =>
		imagesArray.map((image) => (
			<SwiperSlide
				style={{
					textAlign: 'center',
					fontSize: '18px',
					background: '#fff',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					position: 'relative',
				}}
			>
				{({ isNext }) => (
					<>
						<Image src={image} />
						{!isNext && swiper?.currentBreakpoint !== 'max' && <ImageMask />}
					</>
				)}
			</SwiperSlide>
		));
	const renderItems = () => {
		switch (categoriesToRender) {
			case 'Kitchens':
				return renderSlides(kitchensPictures);
			case 'Offices':
				return renderSlides(officesPictures);
			case 'Bathrooms':
				return renderSlides(bathroomsPictures);
			case 'WallUnits':
				return renderSlides(wallUnitsPictures);
			default:
				return renderSlides(kitchensPictures.concat(officesPictures, bathroomsPictures, wallUnitsPictures));
		}
	};

	const classes = styles();

	const closeModal = () => {
		setCategoriesToRender('');
	};

	const TransitionUp = useCallback(
		(props) => <Grow {...props} style={{ transitionDelay: '100ms' }} direction="up" />,
		[]
	);

	return (
		<Dialog
			open={showSlider}
			keepMounted
			TransitionComponent={TransitionUp}
			onClose={closeModal}
			classes={{
				paperWidthSm: classes.paperWidthSm,
				paper: classes.paper,
				paperScrollPaper: classes.paperScrollPaper,
			}}
		>
			<Container>
				<TitleContainer>
					<Text
						type="h2"
						marginTop="24px"
						color={colors.grey900}
						style={{ padding: 24, lineHeight: 2, userSelect: 'none', textShadow: '#666 0.1em 0.1em 0.2em' }}
					>
						OUR PROJECTS
					</Text>
					<ButtonContainer onClick={closeModal}>
						<Close />
					</ButtonContainer>
				</TitleContainer>
				<Swiper
					onSwiper={setSwiper}
					slidesPerView={1}
					pagination={{
						clickable: true,
					}}
					autoplay={{
						delay: 5000,
						disableOnInteraction: false,
					}}
					loop
					navigation
					modules={[Autoplay, Pagination, Navigation]}
					style={{ width: '100%', height: '60vh', minHeight: '360px', marginBottom: 48 }}
					breakpoints={{
						1024: {
							slidesPerView: 3,
						},
					}}
				>
					{renderItems()}
				</Swiper>
			</Container>
		</Dialog>
	);
};

Slider.propTypes = {
	showSlider: PropTypes.string.isRequired,
	categoriesToRender: PropTypes.string.isRequired,
	setCategoriesToRender: PropTypes.func.isRequired,
};

export default Slider;
