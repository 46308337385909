import React from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { ButtonContainer } from './styles';

const TextButton = ({ label, onClick, fullWidth, marginTop, disabled, loading }) => (
	<ButtonContainer marginTop={marginTop}>
		<LoadingButton
			loading={loading}
			disabled={disabled}
			variant="outlined"
			color="inherit"
			fullWidth={fullWidth}
			onClick={onClick}
		>
			{label}
		</LoadingButton>
	</ButtonContainer>
);
TextButton.propTypes = {
	label: PropTypes.string.isRequired,
	fullWidth: PropTypes.bool,
	marginTop: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
};

TextButton.defaultProps = {
	fullWidth: true,
	marginTop: '0px',
};

export default TextButton;
