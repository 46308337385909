import { createTheme } from '@material-ui/core/styles';
import colors from './colors';

const light = createTheme({
	components: {
		overrides: {
			MuiOutlinedInput: {
				root: {
					position: 'relative',
					'& $notchedOutline': {
						borderColor: 'rgba(0, 0, 0, 0.23)',
					},
					'&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
						borderColor: 'green',
						// Reset on touch devices, it doesn't add specificity
						'@media (hover: none)': {
							borderColor: 'rgba(0, 0, 0, 0.23)',
						},
					},
					'&$focused $notchedOutline': {
						borderColor: 'yellow',
						borderWidth: 1,
					},
				},
			},
			MuiFormLabel: {
				root: {
					'&$focused': {
						color: 'orange',
					},
				},
			},
		},
	},
	palette: {
		primary: {
			main: colors.grey500,
			contrastText: colors.white,
		},
		secondary: {
			main: colors.secondary,
			contrastText: colors.white,
		},
		text: {
			primary: colors.grey100,
			secondary: colors.grey300,
			hint: colors.grey300,
		},
	},
	typography: {
		fontFamily: "'Roboto Condensed', 'sans-serif'",
		fontSize: 16,
		h1: {
			fontWeight: 500,
			fontSize: '46px',
			lineHeight: '49px',
		},
		h2: {
			fontWeight: 500,
			fontSize: '34px',
			lineHeight: '36px',
		},
		h3: {
			fontWeight: 500,
			fontSize: '19px',
			lineHeight: '21px',
		},
		h4: {
			fontWeight: 400,
			fontSize: '17px',
			lineHeight: '20px',
		},
		h5: {
			fontWeight: 400,
			fontSize: '14px',
			lineHeight: '17px',
		},
		h6: {
			fontWeight: 900,
			fontSize: '60px',
			lineHeight: '63px',
		},
		body1: {
			fontFamily: "'Roboto Condensed', sans-serif",
			fontWeight: 400,
			fontSize: 16,
		},
	},
});

const dark = createTheme({
	palette: {
		primary: {
			main: colors.primary,
			contrastText: colors.white,
		},
		secondary: {
			main: colors.secondary,
			contrastText: colors.white,
		},
		text: {
			primary: colors.grey100,
			secondary: colors.grey300,
			hint: colors.grey300,
		},
	},
	typography: {
		fontFamily: "'Roboto Condensed', 'sans-serif'",
		fontSize: 16,
		h1: {
			fontWeight: 500,
			fontSize: '46px',
			lineHeight: '49px',
		},
		h2: {
			fontWeight: 500,
			fontSize: '19px',
			lineHeight: '21px',
		},
		h3: {
			fontWeight: 500,
			fontSize: '17px',
			lineHeight: '20px',
		},
		h4: {
			fontWeight: 400,
			fontSize: '17px',
			lineHeight: '20px',
		},
		h5: {
			fontWeight: 400,
			fontSize: '14px',
			lineHeight: '17px',
		},
		h6: {
			fontWeight: 900,
			fontSize: '40px',
			lineHeight: '43px',
		},
	},
});

export default { light, dark };
