import styled from 'styled-components';

import GridImg from '../../../assets/img/Grid.png';

import { breakpoints } from '../../../shared/constants/constants';

export const Container = styled.div`
	height: 100vh;
	width: 100%;
`;

export const VideoContainer = styled.div`
	width: 100%;
	height: ${(props) => (props.secondaryVideo ? 80 : 100)}%;
	margin: ${(props) => (props.secondaryVideo ? '48px 0px' : 0)};
	@media (max-width: ${breakpoints.sm}) {
		padding-top: ${(props) => (props.secondaryVideo ? 0 : 150)}px;
		height: 50%;
	}
`;

export const GridBackgroundImage = styled.div`
	position: absolute;
	background-image: url(${GridImg});
	background-repeat: repeat;
	background-position: center center;
	width: 100%;
	height: 100%;
	@media (max-width: ${breakpoints.sm}) {
		padding-top: 100px;
		height: 50vh;
	}
	position: absolute;
	top: 0;
	/* background-color: red; */
`;

export default Container;
