import { createStyles, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const styles = makeStyles(() =>
	createStyles({
		root: {
			fontWeight: 400,
			fontSize: '1.06rem',
			padding: 0,
			lineHeight: '1.23rem',
			'&:active': {
				opacity: 0.6,
			},
		},
		label: {
			textTransform: 'none',
			textDecoration: 'underline',
		},
	})
);

export const ButtonContainer = styled.div`
	margin-top: ${(props) => props.marginTop};
`;

export default styles;
