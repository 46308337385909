import React, { useState } from 'react';
import { FormControl, FormLabel, Grid, Snackbar } from '@material-ui/core';

import { Alert, Stack } from '@mui/material';
import { verifyAllObrigatoryData } from '../../shared/functions/utils';
import { activeCampaignIds } from '../../shared/constants/enums/activeCampaign';

import * as Manager from './api/ContactFormManager';

import Text from '../../shared/components/text/Text';
import Checkbox from '../../shared/components/checkbox/Checkbox';
import Select from '../../shared/components/select/Select';
import colors from '../../styles/colors';
import TextInput from '../../shared/components/inputs/textInput/TextInput';
import TextButton from '../../shared/components/textButton/TextButton';

import { Container, FormContainer, TitleContainer } from './styles';

const ContactForm = () => {
	const [formValues, setFormValues] = useState({});
	const [acceptTerms, setAcceptTerms] = useState(false);
	const [fieldsWithoutValue, setFieldsWithoutValue] = useState([]);
	const [loading, setLoading] = useState(false);
	const [feedbackMessage, setFeedbackMessage] = useState({
		open: false,
		message: '',
		type: '',
	});

	const setValueFormData = (id, value, type) => {
		if (type === 'checkbox') {
			if (formValues[id] && formValues[id].find((item) => item === value)) {
				setFormValues((oldState) => ({
					...oldState,
					[id]: formValues[id].filter((item) => item !== value),
				}));
			} else {
				setFormValues({
					...formValues,
					...(formValues[id] ? { [id]: [...formValues[id], value] } : { [id]: [value] }),
				});
			}
		} else {
			setFormValues({
				...formValues,
				[id]: value,
			});
		}
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setFeedbackMessage((oldState) => ({ ...oldState, open: false }));
	};

	const textInputs = [
		{
			id: activeCampaignIds.FIRST_NAME,
			label: 'First Name *',
			width: 6,
		},
		{
			id: activeCampaignIds.LAST_NAME,
			label: 'Last Name *',
			width: 6,
		},
		{
			id: activeCampaignIds.EMAIL,
			label: 'Email *',
			width: 6,
		},
		{
			id: activeCampaignIds.PHONE,
			label: 'Phone *',
			width: 6,
		},
		{
			id: activeCampaignIds.ADDRESS,
			label: 'Address *',
			width: 12,
		},
		{
			id: activeCampaignIds.CITY,
			label: 'City *',
			width: 6,
		},
		{
			id: activeCampaignIds.STATE,
			label: 'State *',
			width: 6,
		},
	];

	const checkboxes = [
		{
			id: activeCampaignIds.DESCRIBE_YOUR_PROJECT,
			value: 'Kitchen',
		},
		{
			id: activeCampaignIds.DESCRIBE_YOUR_PROJECT,
			value: 'Bathroom',
		},
		{
			id: activeCampaignIds.DESCRIBE_YOUR_PROJECT,
			value: 'Closets',
		},
		{
			id: activeCampaignIds.DESCRIBE_YOUR_PROJECT,
			value: 'Wall Units',
		},
		{
			id: activeCampaignIds.DESCRIBE_YOUR_PROJECT,
			value: 'Full Home Remodeling',
		},
		{
			id: activeCampaignIds.DESCRIBE_YOUR_PROJECT,
			value: 'Other',
		},
	];

	const selects = [
		{
			id: activeCampaignIds.WHAT_IS_YOUR_DESIRED_PRICE_RANGE,
			label: 'What is your desired price range?',
			options: [
				'$15,000 - $25,000',
				'$25,000 - $35,000',
				'$35,000 - $50,000',
				'$50,000 - $70,000',
				'$70,000 - $100,000',
				'$100,000 & Up',
			],
			width: 12,
		},
		{
			id: activeCampaignIds.HOW_DID_YOU_HEAR_ABOUT_US,
			label: 'How did you hear about us?',
			options: [
				'Houzz',
				'Google',
				'Facebook',
				'Instagram',
				'Online Search',
				'Print Publication',
				'Driving By',
				'Other',
			],
			width: 12,
		},
		{
			id: activeCampaignIds.WHEN_DO_YOU_PLAN_STARTING_YOUR_PROJECT,
			label: 'When do you plan starting your project?',
			options: ['Within 30 days', 'Within 60 days', 'Within 90 days', 'Other'],
			width: 12,
		},
		{
			id: activeCampaignIds.WHEN_IS_THE_BEST_TIME_TO_CONTACT_YOU,
			label: 'When is the best time to contact you?',
			options: ['Morning', 'Noon', 'Afternoon'],
			width: 12,
		},
	];

	const additionalDetails = {
		id: activeCampaignIds.ADDITIONAL_TEXT,
		value: 'Please provide any additional details',
		width: 6,
	};

	const handleSendRequest = async () => {
		const blankFields = verifyAllObrigatoryData(
			[...textInputs, ...checkboxes, ...selects, additionalDetails],
			formValues
		);
		setFieldsWithoutValue(blankFields);
		if (blankFields.length === 0 && acceptTerms) {
			const customFields = [...textInputs.slice(4, 7), checkboxes[0], ...selects, additionalDetails];
			setLoading(true);
			const result = await Manager.sendContactsActiveCampaign(formValues, customFields);
			if (result.errors) {
				setFeedbackMessage({
					open: true,
					message: result.errors[0].title || 'An error has occurred, please try again',
					type: 'error',
				});
			} else {
				setFeedbackMessage({
					open: true,
					message: 'Contact sent successfully!',
					type: 'success',
				});
			}
			setLoading(false);
		}
	};

	return (
		<Container>
			<TitleContainer>
				<Text
					type="h2"
					marginTop="0"
					color={colors.grey900}
					style={{
						padding: 32,
						lineHeight: 2,
						userSelect: 'none',
						textAlign: 'center',
					}}
				>
					READY TO TAKE YOUR FIRST STEP TOWARD A SUCCESSFUL PROJECT?
				</Text>
			</TitleContainer>
			<FormContainer>
				<Text
					type="h3"
					color={colors.grey600}
					style={{
						paddingBottom: 16,
						lineHeight: 2,
						userSelect: 'none',
					}}
				>
					Please fill out the form below so that we can schedule a complimentary consultation in our design center.
				</Text>
				<Grid container spacing={2}>
					{textInputs.map((textInput) => (
						<Grid item xs={textInput.width}>
							<TextInput
								label={textInput.label}
								setValue={(value) => setValueFormData(textInput.id, value, 'input')}
								error={fieldsWithoutValue.length > 0 && !formValues[textInput.id]}
							/>
						</Grid>
					))}
				</Grid>
				<FormControl
					error={
						fieldsWithoutValue.length > 0 &&
						(!formValues[checkboxes[0].id] || formValues[checkboxes[0].id].length === 0)
					}
				>
					<FormLabel style={{ marginTop: 16, marginBottom: 8, fontSize: 19 }} component="legend">
						Describe your project *
					</FormLabel>
					<Grid container spacing={1}>
						{checkboxes.map((box) => (
							<Grid item zeroMinWidth>
								<Checkbox label={box.value} setValue={() => setValueFormData(box.id, box.value, 'checkbox')} />
							</Grid>
						))}
					</Grid>
				</FormControl>
				<Grid container spacing={2} style={{ marginTop: 16 }}>
					{selects.map((item) => (
						<Grid item md={6} xs={item.width}>
							<Select
								item={item}
								value={formValues[item.id]}
								setValue={(value) => setValueFormData(item.id, value, 'select')}
								error={fieldsWithoutValue.length > 0 && !formValues[item.id]}
							/>
						</Grid>
					))}
				</Grid>
				<Grid container spacing={2} style={{ marginTop: 16 }}>
					<Grid item xs={12}>
						<TextInput
							label={additionalDetails.value}
							setValue={(value) => setValueFormData(additionalDetails.id, value, 'input')}
							multiline
							rows={6}
							error={fieldsWithoutValue.length > 0 && !formValues[additionalDetails.id]}
						/>
					</Grid>
				</Grid>
				<Grid style={{ marginTop: 16 }} container spacing={1}>
					<Grid item zeroMinWidth>
						<Checkbox
							label="I accept Lunardi Decor can use any of the above information to contact me."
							setValue={() => setAcceptTerms((oldState) => !oldState)}
						/>
					</Grid>
				</Grid>
				<Grid style={{ marginTop: 16 }} alignItems="center" justifyContent="center" container spacing={1}>
					<Grid item>
						<TextButton
							disabled={!acceptTerms}
							label="Request Free Consultation"
							fullWidth={false}
							onClick={handleSendRequest}
							loading={loading}
						/>
					</Grid>
				</Grid>
			</FormContainer>
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar open={feedbackMessage.open} autoHideDuration={6000} onClose={handleClose}>
					<Alert onClose={handleClose} severity={feedbackMessage.type} sx={{ width: '100%' }}>
						{feedbackMessage.message}
					</Alert>
				</Snackbar>
				{/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
			</Stack>
		</Container>
	);
};

ContactForm.propTypes = {};

export default ContactForm;
