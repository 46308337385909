import React from 'react';
import PropTypes from 'prop-types';

import { Back, Container, Front } from './styles';

const FlipCard = ({ frontCard, backCard }) => (
	<Container>
		<Front>{frontCard}</Front>
		<Back>{backCard}</Back>
	</Container>
);

FlipCard.propTypes = {
	frontCard: PropTypes.instanceOf(Object).isRequired,
	backCard: PropTypes.instanceOf(Object).isRequired,
};

export default FlipCard;
