import styled from 'styled-components';
import colors from '../../../styles/colors';
import { breakpoints } from '../../constants/constants';

export const Front = styled.div`
	width: 100%;
	background-color: ${colors.grey050};
	backface-visibility: hidden;
	transform-style: preserve-3d;
	transition: -webkit-transform ease 500ms;
	transition: transform ease 500ms;
	z-index: 2;
	transform: rotateY(0deg);
	overflow: hidden;
`;
export const Back = styled.div`
	width: 100%;
	position: absolute;
	backface-visibility: hidden;
	transform-style: preserve-3d;
	transition: -webkit-transform ease 500ms;
	transition: transform ease 500ms;
	background-color: ${colors.grey050};
	transform: rotateY(-180deg);
	overflow: hidden;
`;

export const Container = styled.div`
	width: calc(100% - 32px);
	height: 300px;
	@media (min-width: ${breakpoints.sm}) {
		width: calc(50% - 32px);
	}
	@media (min-width: ${breakpoints.md}) {
		width: calc(25% - 32px);
	}
	margin: 16px;
	display: flex;
	transform-style: preserve-3d;
	&:hover ${Front} {
		transform: rotateY(180deg);
	}
	&:hover ${Back} {
		transform: rotateY(0deg);
	}
`;

export const test = styled.div``;
