import React from 'react';
import PropTypes from 'prop-types';
import Text from '../text/Text';

import Logo from '../../../assets/svg/Logo';

import colors from '../../../styles/colors';
import { scrollToRef } from '../../functions/utils';

import {
	Container,
	GoTo,
	GoToContainer,
	LogoContainer,
	CallNowContainer,
	ContactContainer,
	FollowUsContainer,
	SocialNetworksContainer,
	IconContainer,
	InstagramIcon,
	FacebookIcon,
	HouzzIcon,
	PinterestIcon,
	YoutubeIcon,
	WhatsappIcon,
	LocationIcon,
	MailIcon,
	PhoneIcon,
	LicensedContainer,
} from './styles';
import { socialNetworks } from '../../constants/constants';

const Footer = ({ data, refs }) => {
	if (!data) {
		return null;
	}

	return (
		<Container>
			<LogoContainer>
				<Logo width={400} height={166} fill={colors.white} secondaryFill={colors.grey050} />
			</LogoContainer>
			<GoToContainer>
				<Text color={colors.grey050} type="h3" marginTop="0" style={{ fontWeight: 500, userSelect: 'none' }}>
					GO TO
				</Text>
				<GoTo onClick={() => scrollToRef(refs.aboutUs)}>
					<Text color={colors.black} type="h4" marginTop="12px" style={{ fontWeight: 300, userSelect: 'none' }}>
						About Us
					</Text>
				</GoTo>
				<GoTo onClick={() => scrollToRef(refs.ourServices)}>
					<Text color={colors.black} type="h4" marginTop="12px" style={{ fontWeight: 300, userSelect: 'none' }}>
						Our Services
					</Text>
				</GoTo>
				<GoTo onClick={() => scrollToRef(refs.whyHireUs, 280)}>
					<Text color={colors.black} type="h4" marginTop="12px" style={{ fontWeight: 300, userSelect: 'none' }}>
						Why Hire Us
					</Text>
				</GoTo>
				<GoTo onClick={() => scrollToRef(refs.socialNetworks)}>
					<Text color={colors.black} type="h4" marginTop="12px" style={{ fontWeight: 300, userSelect: 'none' }}>
						Social Networks
					</Text>
				</GoTo>
			</GoToContainer>
			<CallNowContainer>
				<Text color={colors.grey050} type="h3" marginTop="0" style={{ fontWeight: 500, userSelect: 'none' }}>
					Call Now For Services!
				</Text>
				<ContactContainer href={`tel:${data.phone.replace(' ', '')}`} target="_blank">
					<PhoneIcon />
					<Text
						color={colors.black}
						type="h4"
						marginTop="0px"
						style={{ marginLeft: '8px', fontSize: 16, fontWeight: 300, userSelect: 'none' }}
					>
						{data.phone}
					</Text>
				</ContactContainer>
				<ContactContainer href={`mailto:${data.email}`} target="_blank">
					<MailIcon />
					<Text
						color={colors.black}
						type="h4"
						marginTop="0px"
						style={{ marginLeft: '8px', fontSize: 16, fontWeight: 300, userSelect: 'none' }}
					>
						{data.email}
					</Text>
				</ContactContainer>
				<ContactContainer href={data.mapLocation} target="_blank">
					<LocationIcon />
					<Text
						color={colors.black}
						type="h4"
						marginTop="0px"
						style={{ marginLeft: '8px', fontSize: 16, fontWeight: 300, userSelect: 'none' }}
					>
						{data.address}
					</Text>
				</ContactContainer>
			</CallNowContainer>
			<FollowUsContainer>
				<Text color={colors.grey050} type="h3" marginTop="0" style={{ fontWeight: 500, userSelect: 'none' }}>
					Follow Us On:
				</Text>
				<SocialNetworksContainer>
					<IconContainer href={socialNetworks.instagram}>
						<InstagramIcon />
					</IconContainer>
					<IconContainer href={socialNetworks.facebook}>
						<FacebookIcon />
					</IconContainer>
					<IconContainer href={socialNetworks.houzz}>
						<HouzzIcon />
					</IconContainer>
					<IconContainer href={socialNetworks.pinterest}>
						<PinterestIcon />
					</IconContainer>
					<IconContainer href={socialNetworks.youtube}>
						<YoutubeIcon />
					</IconContainer>
					<IconContainer href={socialNetworks.whatsapp}>
						<WhatsappIcon />
					</IconContainer>
				</SocialNetworksContainer>
				<LicensedContainer>
					<Text color={colors.grey050} type="h3" marginTop="16px" style={{ fontWeight: 500, userSelect: 'none' }}>
						Licensed and Insured · State General Contractor 1514593
					</Text>
				</LicensedContainer>
			</FollowUsContainer>
		</Container>
	);
};

Footer.propTypes = {
	data: PropTypes.shape({
		logo: PropTypes.string.isRequired,
		address: PropTypes.number.isRequired,
		phone: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		mapLocation: PropTypes.string.isRequired,
	}).isRequired,
	refs: PropTypes.instanceOf(Object).isRequired,
};

export default Footer;
