import styled from 'styled-components';
import Facebook from '../../assets/svg/Facebook';
import Houzz from '../../assets/svg/Houzz';
import Instagram from '../../assets/svg/Instagram';
import Pinterest from '../../assets/svg/Pinterest';
import Whatsapp from '../../assets/svg/Whatsapp';
import Youtube from '../../assets/svg/Youtube';
import colors from '../../styles/colors';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-bottom: 24px;
	user-select: none;
`;

export const SocialMediaContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

export const SocialMedia = styled.div`
	display: flex;
	flex-direction: column;
	margin: 16px 16px 0px 0px;
`;

export const InstagramIcon = styled(Instagram)``;

export const FacebookIcon = styled(Facebook)`
	padding: 16px;
`;

export const HouzzIcon = styled(Houzz)``;

export const PinterestIcon = styled(Pinterest)`
	padding: 0px 16px;
`;
export const YoutubeIcon = styled(Youtube)`
	padding: 0px 10px;
`;
export const WhatsappIcon = styled(Whatsapp)`
	padding: 0px 20px;
`;

export const IconContainer = styled.a`
	display: flex;
	height: 100px;
	width: 100px;
	background-color: ${colors.grey100};
	border-radius: 50px;
	margin: 0px 8px;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	:active {
		box-shadow: 0 1px ${colors.grey200};
		transform: translateY(5px);
	}
	&:not(:hover) ${InstagramIcon} {
		path {
			fill: #000;
		}
	}
	&:not(:hover) ${FacebookIcon} {
		path {
			fill: #000;
		}
	}
	&:not(:hover) ${HouzzIcon} {
		path:nth-child(even) {
			fill: transparent;
		}
		path:nth-child(odd) {
			fill: #000;
		}
	}
	&:not(:hover) ${PinterestIcon} {
		path {
			fill: #000;
		}
	}
	&:not(:hover) ${YoutubeIcon} {
		path {
			fill: #000;
		}
	}
	&:not(:hover) ${WhatsappIcon} {
		path {
			fill: #000;
		}
	}
`;
