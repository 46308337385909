import { createStyles, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../styles/colors';

export const styles = makeStyles(() =>
	createStyles({
		paper: {
			margin: 8,
		},
		paperWidthSm: {
			maxWidth: '100%',
			width: '100%',
		},
		paperScrollPaper: {
			maxHeight: 'calc(100vh - 16px)',
		},
	})
);

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${colors.grey100};
	width: 100%;
`;

export const TitleContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const ButtonContainer = styled.div`
	display: flex;
	height: 24px;
	width: 24px;
	position: absolute;
	align-items: center;
	top: 0;
	right: 0;
	padding: 16px;
`;

export const Image = styled.img`
	object-fit: cover;
	height: 100%;
	width: 100%;
`;

export const ImageMask = styled.div`
	background-color: rgb(255, 255, 255, 0.35);
	height: 100vh;
	width: 100%;
	position: absolute;
`;

export default Container;
