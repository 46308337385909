/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import colors from '../../styles/colors';

const Mail = ({ ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 493.497 493.497"
		style={{
			enableBackground: 'new 0 0 493.497 493.497',
		}}
		xmlSpace="preserve"
		{...props}
	>
		<path
			fill={props.fill}
			d="M444.556 85.218H48.942C21.954 85.218 0 107.171 0 134.16v225.177c0 26.988 21.954 48.942 48.942 48.942h395.613c26.988 0 48.941-21.954 48.941-48.942V134.16c.001-26.989-21.952-48.942-48.94-48.942zm16.314 48.942v225.177c0 2.574-.725 4.924-1.793 7.09L343.74 251.081l117.097-117.097c0 .065.033.112.033.176zM32.628 359.336V134.16c0-.064.033-.11.033-.175l117.097 117.097L34.413 366.426c-1.06-2.166-1.785-4.515-1.785-7.09zm219.156-62.434c-2.692 2.691-7.378 2.691-10.07 0L62.667 117.846h368.172L251.784 296.902zm-78.957-22.75 45.818 45.819c7.512 7.511 17.493 11.645 28.104 11.645 10.61 0 20.592-4.134 28.104-11.645l45.82-45.819 101.49 101.499H71.327l101.5-101.499z"
		/>
	</svg>
);

Mail.defaultProps = {
	fill: colors.grey900,
};
Mail.propTypes = {
	fill: PropTypes.string,
};

export default Mail;
