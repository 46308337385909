import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../shared/components/text/Text';
import colors from '../../styles/colors';
import {
	Container,
	FacebookIcon,
	HouzzIcon,
	IconContainer,
	InstagramIcon,
	PinterestIcon,
	SocialMedia,
	SocialMediaContainer,
	WhatsappIcon,
	YoutubeIcon,
} from './styles';
import { socialNetworks } from '../../shared/constants/constants';

const FollowUs = ({ followUsRef }) => (
	<Container ref={followUsRef}>
		<Text type="h2" marginTop="24px" color={colors.grey900} style={{ padding: 24, lineHeight: 2, userSelect: 'none' }}>
			FOLLOW US ON
		</Text>
		<SocialMediaContainer>
			<SocialMedia>
				<IconContainer href={socialNetworks.instagram}>
					<InstagramIcon />
				</IconContainer>
			</SocialMedia>
			<SocialMedia>
				<IconContainer href={socialNetworks.facebook}>
					<FacebookIcon />
				</IconContainer>
			</SocialMedia>
			<SocialMedia>
				<IconContainer href={socialNetworks.houzz}>
					<HouzzIcon />
				</IconContainer>
			</SocialMedia>
			<SocialMedia>
				<IconContainer href={socialNetworks.pinterest}>
					<PinterestIcon />
				</IconContainer>
			</SocialMedia>
			<SocialMedia>
				<IconContainer>
					<YoutubeIcon />
				</IconContainer>
			</SocialMedia>
			<SocialMedia>
				<IconContainer href={socialNetworks.whatsapp}>
					<WhatsappIcon />
				</IconContainer>
			</SocialMedia>
		</SocialMediaContainer>
	</Container>
);

FollowUs.propTypes = {
	followUsRef: PropTypes.instanceOf(Object).isRequired,
};

export default FollowUs;
