import React from 'react';

import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import Female from '../../assets/svg/Female';
import Male from '../../assets/svg/Male';
import Text from '../../shared/components/text/Text';
import colors from '../../styles/colors';
import { AvatarContainer, Container, MessageContainer, NameContainer, SlideReview, TitleContainer } from './styles';

const Testimonials = () => {
	const reviews = [
		{
			name: 'Christian Abernathy',
			message:
				'Our experience with Lunardi Decor in remodeling and updating our bathrooms was thoroughly positive. Vanessa Interior Desing provided valuable advice and helpful suggestions about the project. Vanessa pointed us toward the suppliers we needed to contact and gave clear, careful instructions about what we should get and how we should proceed. Vanessa was resourceful in helping us generate ideas about what we wanted and in steering us toward what was feasible. Vanessa was also punctual in his meetings with us, as were his employees who did the work. What he said he would do, he did. Lunardi Decor, employees did great work. They were professional, courteous, and skillful in their work; no, more than skillful, they were artistic craftsmen who took pride in producing aesthetically pleasing work in 3D. They came and left on time, treated our house and furnishings with respect, properly disposed of the old materials and fittings, and created minimal intrusion while they were here. We are very pleased with Lunardi Decor and the folks who work with him at Sterling Works.',
			type: 'male',
		},
		{
			name: 'Daniela Takahara',
			message:
				'Wow! Lunardi Decor did a great job in my house! The general contractor was very professional and so were the crew. We changed the entire tile in our house in a record time! Amazing! We are impressed! We highly recommend Lunardi Decor!',
			type: 'female',
		},
		{
			name: 'John Armstrong',
			message:
				'Lunardi Decor is a professional company that does quality work. We had our master bath renovated by Lunardi Decor and are very happy with the results. Vanessa Interior Designer came up with a unique design. The 3D Desing helped us visualize, that gave us maximum space for a roomy, stylish neo-angle shower, and addressed some mechanical issues we had. Vanessa efficiently led us through the selection of the materials, some changes we made along the way, and Lunardi Decor developed a schedule for the project, which told us exactly what would be done each day. Work proceeded on time and efficiently. All the contractors licensed, kept to the schedule and did quality work. Leandro, addressed all issues and changes that came up during the project and made sure everything was done to our satisfaction. He periodically checked in on the quality of work and was always available for our questions. We highly recommend Lunardi Decor.',
			type: 'male',
		},
		{
			name: 'Rita Levitt',
			message:
				'We were very pleased with the professional way Lunardi Décor updated our kitchen.  They are extremely knowledgeable and the makeover went very smoothly.  They are very caring and all follow-up and appointments were always done on time.  I would highly recommend them.',
			type: 'female',
		},
		{
			name: 'Luana Rogers',
			message: `My husband and I were so happy with our kitchen remodel with Lunardi Decor. Interior Desing were really crucial in helping us get exactly what we wanted. They listened to what we were hoping the kitchen would look like and helped us make it real. There are so many logistical things that you don't think about which they steered us through, all the way from the big items like choosing the cabinets, using nearby space to make the kitchen much bigger and finding a contractor, down to small details like which way a cabinet door swings, lighting, how to get better storage, panelling, etc. We never would have been able to do this on our own. Interior Desing very responsive to questions and communicated closely via phone and email. Also their detailed 3D Desing helped us visualize the kitchen easily and look at it from different angles. Finally, the quality of the materials used was very high and it looks like we spent more than we did. The budget was set and did not change unexpectedly. We were very happy with Lunardi Decor and would recommend them highly.`,
			type: 'female',
		},
		{
			name: 'Pablo Williams',
			message: `These guys made an astonishing job in my home! All started with a fabulous consulting service provided by the interior designer Vanessa Lunardi, by guiding me with great customized remodeling ideas and, most importantly, affordable. On the execution phase, Leandro Lunardi passionately and tirelessly led his team while caring for the minimum details and making sure the final result was delivered exactly as projected. Excellent teamwork, I strongly recommend Lunardi Decor.`,
			type: 'male',
		},
		{
			name: 'Clayson Cypriano',
			message: `Thanks, Lunardi Decor! My kitchen looks amazing! Lunardi Decor took care of everything- schedule, follow up with the crew, permit, tiles, crown molding, backsplash, countertop.... I’m in love with my new island- looks great and very functional! Great work, Lunardi Decor! I higly recommend!!`,
			type: 'male',
		},
		{
			name: 'Gustavo Fonseca',
			message: `Great company! I did my entire floor and remolded my bathrooms and kitchens  with Lunardi Decor. Great work! Amazing how fast they did it! Very pleased! Higly recommend Lunardi Decor!`,
			type: 'male',
		},
	];

	const renderAvatar = (type) => {
		if (type === 'male') {
			return <Male />;
		}
		return <Female />;
	};

	const renderReview = () =>
		reviews.map((review) => (
			<SwiperSlide>
				<SlideReview>
					<MessageContainer>
						<Text
							type="h4"
							marginTop="24px"
							color={colors.grey900}
							style={{ marginBottom: 16, lineHeight: 2, userSelect: 'none' }}
						>
							{review.message}
						</Text>
						⭐️⭐️⭐️⭐️⭐️
					</MessageContainer>
					<AvatarContainer>{renderAvatar(review.type)}</AvatarContainer>
					<NameContainer>
						<Text type="h4" color={colors.grey900} style={{ lineHeight: 2, userSelect: 'none', fontWeight: 700 }}>
							{review.name}
						</Text>
					</NameContainer>
				</SlideReview>
			</SwiperSlide>
		));
	return (
		<Container>
			<TitleContainer>
				<Text
					type="h2"
					marginTop="24px"
					color={colors.grey900}
					style={{ padding: 24, lineHeight: 2, userSelect: 'none', textAlign: 'center' }}
				>
					WHAT OUR CLIENTS ARE SAYING
				</Text>
			</TitleContainer>
			<Swiper
				style={{
					width: '100%',
					height: '100%',
				}}
				loop
				autoplay={{
					delay: 6000,
					disableOnInteraction: false,
				}}
				modules={[Autoplay]}
			>
				{renderReview()}
			</Swiper>
		</Container>
	);
};

export default Testimonials;
