import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Logo from '../../../assets/svg/Logo';

import Text from '../text/Text';
import colors from '../../../styles/colors';

import {
	// AddressContainer,
	// Address,
	// InfoContainer,
	Container,
	LogoContainer,
	PhoneContainer,
	ContactContainer,
	NavigationContainer,
	IconContainer,
	InstagramIcon,
	MailIcon,
	Contacts,
	MailContainer,
	InstagramContainer,
	// PhoneContainer2,
	LocationContainer,
	LocationIcon,
	PhoneIcon,
	SocialNetworksContainer,
	TextContacts,
	DescriptionContacts,
	HouzzContainer,
	HouzzIcon,
	MenuIconContainer,
	WhatsappContainer,
	WhatsappIcon,
	GoToContainer,
	GoTo,
} from './styles';

import NavBar from '../navBar/NavBar';
import MenuIcon from '../../../assets/svg/MenuIcon';
import { scrollToRef } from '../../functions/utils';
import { socialNetworks } from '../../constants/constants';

const Header = ({ data, hide, refs }) => {
	const [open, setOpen] = useState(false);
	if (!data) {
		return null;
	}
	return (
		<Container hide={hide}>
			<ContactContainer>
				<Contacts>
					<TextContacts>
						<Text color={colors.grey050} type="h5" marginTop="0" style={{ userSelect: 'none' }}>
							Contact us:
						</Text>
					</TextContacts>
					<PhoneContainer href={`tel:${data.phone.replace(' ', '')}`} target="_blank">
						<IconContainer>
							<PhoneIcon />
						</IconContainer>
						<DescriptionContacts>
							<Text color={colors.grey900} type="h5" marginTop="0" style={{ userSelect: 'none' }}>
								Phone: {data.phone}
							</Text>
						</DescriptionContacts>
					</PhoneContainer>
					<MailContainer href={`mailto:${data.email}`} target="_blank">
						<IconContainer>
							<MailIcon />
						</IconContainer>
						<DescriptionContacts>
							<Text color={colors.grey900} type="h5" marginTop="0" style={{ userSelect: 'none' }}>
								E-mail: {data.email}
							</Text>
						</DescriptionContacts>
					</MailContainer>
					<LocationContainer href={data.mapLocation} target="_blank">
						<IconContainer>
							<LocationIcon />
						</IconContainer>
						<DescriptionContacts>
							<Text color={colors.grey900} type="h5" marginTop="0" style={{ userSelect: 'none' }}>
								Location: {data.address}
							</Text>
						</DescriptionContacts>
					</LocationContainer>
				</Contacts>
				<SocialNetworksContainer>
					<Text color={colors.grey050} type="h5" marginTop="0" style={{ userSelect: 'none' }}>
						Follow us on:
					</Text>
					<InstagramContainer href={socialNetworks.instagram}>
						<IconContainer>
							<InstagramIcon />
						</IconContainer>
					</InstagramContainer>
					<HouzzContainer href={socialNetworks.houzz}>
						<IconContainer>
							<HouzzIcon />
						</IconContainer>
					</HouzzContainer>
					<WhatsappContainer href={socialNetworks.whatsapp}>
						<IconContainer>
							<WhatsappIcon />
						</IconContainer>
					</WhatsappContainer>
				</SocialNetworksContainer>
			</ContactContainer>
			<NavigationContainer>
				<MenuIconContainer onClick={() => setOpen(true)}>
					<MenuIcon />
				</MenuIconContainer>
				<NavBar open={open} setOpen={setOpen} refs={refs} />
				<NavigationContainer>
					<GoToContainer left>
						<GoTo onClick={() => scrollToRef(refs.aboutUs)}>
							<Text
								color={colors.grey900}
								type="h4"
								marginTop="0px"
								style={{ fontSize: 15, letterSpacing: 2, fontWeight: 400, userSelect: 'none' }}
							>
								ABOUT US
							</Text>
						</GoTo>
						<GoTo onClick={() => scrollToRef(refs.ourServices)}>
							<Text
								color={colors.grey900}
								type="h4"
								marginTop="0px"
								style={{ fontSize: 15, letterSpacing: 2, fontWeight: 400, userSelect: 'none' }}
							>
								SERVICES
							</Text>
						</GoTo>
					</GoToContainer>
					<LogoContainer>
						<Logo />
					</LogoContainer>
					<GoToContainer>
						<GoTo onClick={() => scrollToRef(refs.whyHireUs)}>
							<Text
								color={colors.grey900}
								type="h4"
								marginTop="0px"
								style={{ fontSize: 15, letterSpacing: 2, fontWeight: 400, userSelect: 'none' }}
							>
								WHY HIRE US
							</Text>
						</GoTo>
						<GoTo onClick={() => scrollToRef(refs.socialNetworks)}>
							<Text
								color={colors.grey900}
								type="h4"
								marginTop="0px"
								style={{ fontSize: 15, letterSpacing: 2, fontWeight: 400, userSelect: 'none' }}
							>
								CONTACTS
							</Text>
						</GoTo>
					</GoToContainer>
				</NavigationContainer>
				{/* <InfoContainer>
					<AddressContainer>
						<Address>
							<Text type="h5" marginTop="0" color={colors.grey010}>
								{data.address}
							</Text>
							<Text type="h5" marginTop="0" color={colors.grey010}>
								{data.address}
							</Text>
						</Address>
					</AddressContainer>
					<PhoneContainer2>
						<Text type="h4" marginTop="0" color={colors.grey010} textDecoration>
							{data.phone}
						</Text>
					</PhoneContainer2>
				</InfoContainer> */}
			</NavigationContainer>
		</Container>
	);
};

Header.propTypes = {
	data: PropTypes.shape({
		logo: PropTypes.string.isRequired,
		address: PropTypes.number.isRequired,
		phone: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		mapLocation: PropTypes.string.isRequired,
	}).isRequired,
	hide: PropTypes.bool.isRequired,
	refs: PropTypes.instanceOf(Object).isRequired,
};

export default Header;
