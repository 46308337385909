import PropTypes from 'prop-types';
import React from 'react';

import { StyledTextField } from './styles';

const TextInput = ({ label, value, setValue, multiline, rows, error }) => (
	<StyledTextField
		fullWidth
		variant="outlined"
		label={label}
		value={value}
		hasValue={value}
		onChange={(e) => setValue(e.target.value)}
		multiline={multiline}
		rows={rows}
		error={error}
	/>
);

TextInput.defaultProps = {
	multiline: false,
	rows: 1,
};
TextInput.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	setValue: PropTypes.func.isRequired,
	multiline: PropTypes.bool,
	rows: PropTypes.number,
	error: PropTypes.bool.isRequired,
};

export default TextInput;
