/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

const Service = ({ ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 32 32"
		style={{
			enableBackground: 'new 0 0 32 32',
		}}
		xmlSpace="preserve"
		{...props}
	>
		<path d="M16 31.36C7.53 31.36.64 24.47.64 16S7.53.64 16 .64 31.36 7.53 31.36 16 24.47 31.36 16 31.36zm-.615-.733c.204.009.409.013.615.013 4.741 0 8.963-2.265 11.64-5.77V12.008h.721v11.831A14.564 14.564 0 0 0 30.64 16c0-1.999-.402-3.904-1.13-5.641L18.363 3.924l.36-.624 10.249 5.917c-2.174-4.141-6.273-7.119-11.101-7.738L6.723 7.916l-.36-.624 10.252-5.919A13.921 13.921 0 0 0 16 1.36c-4.741 0-8.963 2.266-11.64 5.771v12.862h-.72V8.161A14.558 14.558 0 0 0 1.36 16c0 1.998.402 3.903 1.13 5.641l11.147 6.436-.36.623-10.249-5.918c2.174 4.142 6.273 7.12 11.101 7.739l11.148-6.437.359.623-10.251 5.92zm-4.712-8.951a2.361 2.361 0 0 1-2.048-1.18 2.339 2.339 0 0 1-.233-1.789 2.343 2.343 0 0 1 1.097-1.434l5.485-3.167a4.375 4.375 0 0 1 2.176-3.565 4.356 4.356 0 0 1 5.291.721.36.36 0 0 1-.077.564l-3.354 1.937a.641.641 0 0 0 .641 1.109l3.354-1.937a.36.36 0 0 1 .526.215 4.355 4.355 0 0 1-2.021 4.943 4.383 4.383 0 0 1-4.176.103l-5.484 3.166a2.369 2.369 0 0 1-1.177.314zm8.657-11.001c-.623 0-1.252.16-1.821.489a3.657 3.657 0 0 0-1.821 3.153.358.358 0 0 1-.18.311l-5.659 3.268a1.632 1.632 0 0 0-.762.996c-.114.425-.057.866.161 1.244a1.644 1.644 0 0 0 2.241.601l5.66-3.268a.36.36 0 0 1 .359 0 3.66 3.66 0 0 0 3.642-.001 3.638 3.638 0 0 0 1.785-3.664l-2.926 1.689a1.361 1.361 0 0 1-1.857-.498 1.362 1.362 0 0 1 .498-1.858l2.925-1.689a3.648 3.648 0 0 0-2.245-.773z" />
		<path
			style={{
				fill: 'none',
			}}
			d="M0 0h32v32H0z"
		/>
	</svg>
);

export default Service;
