import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 160px;
`;

export const ImageContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

export const HouzzBadge = styled.img`
	margin: 8px;
`;
