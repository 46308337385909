import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';

const Text = ({ children, type, marginTop, noWrap, textDecoration, style, color }) => {
	const theme = useTheme();
	const colorText = color || theme.palette.text.primary;
	return (
		<Typography
			variant={type}
			style={{
				...style,
				textDecoration: textDecoration ? 'underline' : 'none',
				marginTop,
				color: colorText,
			}}
			noWrap={noWrap}
		>
			{children}
		</Typography>
	);
};

Text.propTypes = {
	children: PropTypes.node.isRequired,
	type: PropTypes.string,
	marginTop: PropTypes.string,
	noWrap: PropTypes.bool,
	textDecoration: PropTypes.bool,
	style: PropTypes.instanceOf(Object),
	color: PropTypes.string,
};

Text.defaultProps = {
	type: 'h1',
	marginTop: '16px',
	noWrap: false,
	textDecoration: false,
	style: {},
	color: '',
};

export default Text;
