import { FormControl, InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import colors from '../../../styles/colors';

import { StyledSelect, StyledMenuItem } from './styles';

const Select = ({ item, value, setValue, error }) => {
	const handleChange = (event) => {
		setValue(event.target.value);
	};
	return (
		<FormControl fullWidth error={error}>
			<InputLabel style={{ fontFamily: 'Roboto Condensed', color: colors.grey600 }}>{item.label}</InputLabel>
			<StyledSelect
				MenuProps={{
					sx: {
						'&& .Mui-selected': {
							backgroundColor: colors.grey200,
						},
					},
				}}
				value={value || ''}
				label={item.label}
				onChange={handleChange}
			>
				{item.options.map((option) => (
					<StyledMenuItem value={option}>{option}</StyledMenuItem>
				))}
			</StyledSelect>
		</FormControl>
	);
};

Select.propTypes = {
	item: PropTypes.instanceOf(Object).isRequired,
	value: PropTypes.string.isRequired,
	setValue: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
};

export default Select;
