import styled from 'styled-components';
import { breakpoints } from '../../constants/constants';

export const GoToContainer = styled.div`
	display: flex;
	flex: 1;
	margin-top: -8px;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;
	/* @media (min-width: ${breakpoints.sm}) {
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
	} */
`;

export const GoTo = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	height: 40px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	/* :active {
		opacity: 0.6;
	} */
	/* @media (max-width: ${breakpoints.sm}) {
		margin: 6px 0px;
	} */
`;
