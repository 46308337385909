import React from 'react';

import FlipCard from '../../shared/components/flipCard/FlipCard';
import Text from '../../shared/components/text/Text';

import colors from '../../styles/colors';

import FirstConsultation from '../../assets/img/cards/FirstConsultation.jpg';
import SelectingProducts from '../../assets/img/cards/SelectingProducts.jpg';
import Render from '../../assets/img/cards/Render.jpg';
import Installation from '../../assets/img/cards/Installation.jpg';

import { Container, FlipContainer, NumberContainer, TextContainer, TitleContainer } from './styles';

const Solutions = () => {
	const cards = [
		{
			image: FirstConsultation,
			title: 'FIRST CONSULTATION',
			text: 'When you make an appointment with us, our Interior Designer comes to you for a Free in-Home Consultation. We’ll take a look at your space, see what kind of service you need, take some pictures and measurements and give you suggestions according to your preferences.',
			number: '01',
		},
		{
			image: SelectingProducts,
			title: 'SELECTING PRODUCTS',
			text: 'Once the service is chosen, our Interior Design will create a customized floor plan for you and guide you in selecting products: tiles, handles, countertops,  backsplash, sink, faucets, decorative accents, accessories, etc. We will send you a quote: our prices are very affordable and you’ll get nothing less then a high-end service quality.',
			number: '02',
		},
		{
			image: Render,
			title: '3D RENDER',
			text: 'Wondering how your project is going to look like in the end? After the quote is approved, out team is going to make a free 3D Rendering of your project so you can already have a vision of the finished project before we even start building it. We believe this is our market differential, offering you a service with no surprises in the end.',
			number: '03',
		},
		{
			image: Installation,
			title: 'INSTALLATION',
			text: 'We guarantee a stress-free the delivery and installation of the final project meeting your preferences and expectations. You don’t have to worry about anything. At Lunardi Decor you’ll find a friendly, dedicated, fast, organized and responsible team specialized in making your dream house come true. ',
			number: '04',
		},
	];

	const renderFlipCards = () =>
		cards.map((card) => (
			<FlipCard
				backCard={
					<>
						<FlipContainer imageBackground={card.image}>
							{/* <Text type="h2" marginTop="0" style={{ textShadow: '2px 4px 3px rgba(0,0,0,1)', fontFamily: 'Roboto' }}>
							{card.title}
						</Text> */}
						</FlipContainer>
						<TextContainer>
							<Text
								type="h4"
								marginTop="16px"
								style={{ padding: '0px 32px', textShadow: '2px 4px 3px rgba(0,0,0,1)', fontFamily: 'Roboto' }}
							>
								{card.text}
							</Text>
						</TextContainer>
						<NumberContainer>
							<Text
								type="h4"
								marginTop="0px"
								style={{
									paddingBottom: 16,
									fontWeight: 900,
									fontSize: '60px',
									textShadow: '2px 4px 3px rgba(0,0,0,1)',
									fontFamily: 'Roboto',
								}}
							>
								{card.number}
							</Text>
						</NumberContainer>
					</>
				}
				frontCard={
					<>
						<FlipContainer front imageBackground={card.image} />
						<TextContainer>
							<Text type="h2" marginTop="0" style={{ textShadow: '2px 4px 3px rgba(0,0,0,1)', fontFamily: 'Roboto' }}>
								{card.title}
							</Text>
						</TextContainer>
						<NumberContainer>
							<Text
								type="h4"
								marginTop="0px"
								style={{
									paddingBottom: 16,
									fontWeight: 900,
									fontSize: '60px',
									textShadow: '2px 4px 3px rgba(0,0,0,1)',
									fontFamily: 'Roboto',
								}}
							>
								{card.number}
							</Text>
						</NumberContainer>
					</>
				}
			/>
		));

	return (
		<>
			<TitleContainer>
				<Text type="h2" marginTop="0" color={colors.grey900} style={{ padding: 24, lineHeight: 2, userSelect: 'none' }}>
					YOU DREAM IT, WE DESIGN AND BUILD IT
				</Text>
			</TitleContainer>
			<Container>{renderFlipCards()}</Container>
		</>
	);
};

export default Solutions;
