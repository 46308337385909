export const activeCampaignIds = {
	DESCRIBE_YOUR_PROJECT: 1,
	ADDITIONAL_TEXT: 2,
	WHAT_IS_YOUR_DESIRED_PRICE_RANGE: 3,
	HOW_DID_YOU_HEAR_ABOUT_US: 4,
	WHEN_DO_YOU_PLAN_STARTING_YOUR_PROJECT: 5,
	WHEN_IS_THE_BEST_TIME_TO_CONTACT_YOU: 6,
	ADDRESS: 8,
	CITY: 9,
	STATE: 10,
	FIRST_NAME: 'firstName',
	LAST_NAME: 'lastName',
	EMAIL: 'email',
	PHONE: 'phone',
};

export default activeCampaignIds;
